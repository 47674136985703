import styled from 'styled-components';

export const AuthFormForgotPassWrapper = styled.div``;

export const AuthFormForgotPassLabel = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
`;

export const AuthFormForgotPassForm = styled.form`
    margin: 20px 0px;
`;

export const AuthFormForgotPassFormItem = styled.div`
    margin-bottom: 20px;
`;

export const AuthFormForgotPassSignUpText = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
`;

export const AuthFormForgotPassSignUpTextItem = styled.div`
    margin-right: 5px;
`;
