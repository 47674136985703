import styled, { css } from 'styled-components';

export const BusinessPageWrapper = styled.div`
    position: relative;
`;

export const BusinessPageContent = styled.div`
    display: flex;
`;

export const BusinessPageBusinessContent = styled.div`
    width: 50%;
    padding: 32px 43px;
`;

export const BusinessPageOrderContent = styled.div`
    width: 50%;
    height: 500vh;
    background-color: #0e0f3e;
`;

export const BusinessPageInfoBlock = styled.div`
    margin-top: 30px;
`;

export const BusinessPageInfoBlockAvatar = styled.img`
    width: 70px;
    height: 70px;
    border-radius: 35px;
`;

export const BusinessPageInfoBlockGridSecondRow = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    margin-top: 11px;
`;

export const BusinessPageInfoBlockGridThirdRow = styled.div`
    display: flex;
    margin-top: 11px;
`;

export const BusinessPageInfoBlockAddress = styled.div`
    display: flex;
    alingn-items: center;
`;

export const BusinessPageInfoBlockAddressText = styled.div`
    margin-left: 5px;
`;

export const BusinessPageInfoBlockRating = styled.div`
    margin-left: 18px;
    display: flex;
    align-items: center;
`;

export const BusinessPageInfoBlockRatingText = styled.div`
    margin-right: 6px;
`;

export const BusinessPageInfoBlockReviews = styled.div`
    margin-left: 8px;
`;

export const BusinessPageInfoBlockPartners = styled.div`
    height: 17px;
    display: flex;
    align-items: center;
    margin-top: 21px;
`;

export const BusinessPageInfoBlockPartnersImage = styled.img`
    height: 17px;
    margin-left: 10px;
`;

export const BusinessPageCarousel = styled.div`
    margin-top: 20px;
`;

export const BusinessPageAdditionalInfoBlock = styled.div``;

export const BusinessPageAdditionalInfoBlockAmenities = styled.div`
    margin-top: 33px;
    display: flex;
`;

export const BusinessPageAdditionalInfoBlockAmenitiesItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: 12px;
`;

export const BusinessPageAdditionalInfoBlockAmenitiesItem = styled.div`
    height: 18px;
    border: solid 1px #0e0f3e;
    margin-right: 6px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 0 7px;
    margin-bottom: 10px;
`;

export const BusinessPageAdditionalInfoBlockAmenitiesItemIcon = styled.div`
    margin-right: 5px;
    display: flex;
    justify-content: center;
`;

export const BusinessPageAdditionalInfoBlockShedule = styled.div`
    margin-top: 12px;
`;

export const BusinessPageAdditionalInfoBlockSheduleRow = styled.div`
    margin-top: 24px;
`;

export const BusinessPageAdditionalInfoBlockAwards = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    height: 148px;
`;

export const BusinessPageAdditionalInfoBlockAward = styled.div`
    display: flex;
    align-items: center;
    margin-right: 45px;
`;

export const BusinessPageAdditionalInfoBlockAwardText = styled.div`
    width: 50px;
    margin-left: 14px;
`;

export const BusinessPageBrands = styled.div`
    margin-top: 55px;
`;

export const BusinessPageBrandsTitle = styled.div`
    display: flex;
`;

export const BusinessPageBrandsTitleButton = styled.div`
    margin-left: 5px;
    display: flex;
`;

type BusinessPageBrandsTitleButtonIconProps = {
    isOpen: boolean;
};

export const BusinessPageBrandsTitleButtonIcon = styled.div<
    BusinessPageBrandsTitleButtonIconProps
>`
    margin-left: 8px;
    display: flex;
    align-items: center;

    ${({ isOpen }) => css`
        transform: rotate(${isOpen ? '180deg' : '0deg'});
    `}
`;

type BusinessPageBrandsListProps = {
    isOpen: boolean;
};

export const BusinessPageBrandsList = styled.div<BusinessPageBrandsListProps>`
    margin-top: 30px;
    ${({ isOpen }) => css`
        display: ${isOpen ? 'flex' : 'none'};
    `}
    flex-wrap: wrap;
    justify-content: flex-start;
`;

export const BusinessPageBrandsListItem = styled.div`
    display: flex;
    align-items: center;
    margin-top: 14px;
    width: 33%;
`;

export const BusinessPageBrandsListItemText = styled.div`
    margin-left: 12px;
`;

export const BusinessPageDescription = styled.div`
    margin-top: 40px;
`;

export const BusinessPageDescriptionHeader = styled.div`
    margin-bottom: 25px;
`;

export const BusinessPageRating = styled.div`
    margin-top: 55px;
    width: 350px;
    ${({ theme }) => css`
        border-bottom: 1px solid ${theme.palette.Secondary};
    `};
`;

export const BusinessPageTotalRating = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
`;
