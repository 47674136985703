import { Exception } from '@serviceplace/core';
import { GeoPositionStore } from '@source/store/geo-position.store';
import { GeoPositionApi } from '@source/api/geo-position.api';
import { CityModel } from '@source/data/model';

export class GeoPositionException extends Exception {
    constructor(reason: string) {
        super(`GeoPositionService error. Reason ${reason}`);
    }
}

export interface GeoPositionService {
    loadGeoPosition(lat?: string, lng?: string): Promise<CityModel>;
}

export class GeoPositionServiceImpl implements GeoPositionService {
    constructor(
        private _geoPositionStore: GeoPositionStore,
        private _geoPositionApi: GeoPositionApi,
    ) {}

    async loadGeoPosition(lat?: string, lng?: string): Promise<CityModel> {
        try {
            const geoPosition = await this._geoPositionApi.loadGeoPosition(
                lat,
                lng,
            );
            this._geoPositionStore.geoPosition = geoPosition;
            return geoPosition;
        } catch (err) {
            throw new GeoPositionException(err);
        }
    }
}
