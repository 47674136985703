import { SearchVehicleModel } from '@source/data/model';
import { AppConfig } from '@source/config/app.config';
import { SearchVehicleDto, ServerResponseDto } from '@source/data/dto';
import { InternationalizationStore } from '@serviceplace/core';
import { SearchVehicleDtoToModelConverter } from '@source/converter/search-vehicle-dto-to-model.converter';

export type SearchUserCredential = {
    email: string;
    password: string;
};

export type SearchRegistrationType = SearchUserCredential & {
    promoNotifyAgree?: boolean;
};

export interface SearchApi {
    searchVehicle(licensePlate: string): Promise<SearchVehicleModel[]>;
}

export class SearchApiImpl implements SearchApi {
    constructor(
        private _appConfig: AppConfig,
        private _internationalizationStore: InternationalizationStore,
        private _searchVehicleDtoToModelConverter: SearchVehicleDtoToModelConverter,
    ) {}

    async searchVehicle(licensePlate: string): Promise<SearchVehicleModel[]> {
        const url = `${this._appConfig.host}/api/insurances/v1/search/vehicle?license_plate=${licensePlate}`;

        const res: Response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept-Language': this._internationalizationStore
                    .currentLocale,
            },
        });

        const resData: ServerResponseDto<SearchVehicleDto[]> = await res.json();

        return this._searchVehicleDtoToModelConverter.convertArray(
            resData.data,
        );
    }
}
