import { Exception } from '@serviceplace/core';
import { UserInfoStore } from '@source/store/user-info.store';
import { CityModel } from '@source/data/model';

export const userGeoPositionCityKey = 'userGeoPositionCity';
export const userSelectedCityKey = 'userSelectedCity';

export class UserInfoException extends Exception {
    constructor(reason: string) {
        super(`UserInfoException error. Reason ${reason}`);
    }
}

export interface UserInfoService {
    initialValues(): void;
    setUserCity(city: CityModel): void;
    setSelectedCity(city: CityModel): void;
}

export class UserInfoServiceImpl implements UserInfoService {
    constructor(private _userInfoStore: UserInfoStore) {}

    initialValues(): void {
        const city = localStorage.getItem(userGeoPositionCityKey);
        const selectedCity = localStorage.getItem(userSelectedCityKey);

        if (city && city !== 'undefined') {
            this._userInfoStore.geoPositionCity = JSON.parse(city);
        }
        if (selectedCity && selectedCity !== 'undefined') {
            this._userInfoStore.selectedCity = JSON.parse(selectedCity);
        }
    }

    setUserCity(city: CityModel): void {
        localStorage.setItem(userGeoPositionCityKey, JSON.stringify(city));
        this._userInfoStore.geoPositionCity = city;
        this.setSelectedCity(city);
    }

    setSelectedCity(city: CityModel): void {
        localStorage.setItem(userSelectedCityKey, JSON.stringify(city));
        this._userInfoStore.selectedCity = city;
    }
}
