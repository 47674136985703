import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
    Button,
    Text,
    TextColor,
    TextFontSize,
    TextWeight,
    Input,
} from '@autobooking/components';
import {
    AuthFormForgotPassWrapper,
    AuthFormForgotPassLabel,
    AuthFormForgotPassForm,
    AuthFormForgotPassFormItem,
    AuthFormForgotPassSignUpText,
    AuthFormForgotPassSignUpTextItem,
} from './auth-form-forgot-pass.component.styles';
import { APP_CONFIG_KEY } from '@source/config/app.config';
import { useYupValidationResolver } from '@source/hooks/yup-validation-resolver.hook';

enum ForgotPasswordForm {
    email = 'email',
}

export type ForgotPasswordFormProps = {
    [ForgotPasswordForm.email]: string;
};

export type AuthFormForgotPassProps = {
    onOpenRegistration: () => void;
    onRecoveryPassword: (value: ForgotPasswordFormProps) => void;
};

export const AuthFormForgotPass: React.FC<AuthFormForgotPassProps> = ({
    onOpenRegistration,
    onRecoveryPassword,
}) => {
    const [t] = AppCore.useTranslator(APP_CONFIG_KEY);

    const recoveryPassSchema = yup.object().shape({
        [ForgotPasswordForm.email]: yup
            .string()
            .label(t('auth: fields: email'))
            .required()
            .email(), // pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
    });

    const recoveryPassResolver = useYupValidationResolver(recoveryPassSchema);

    const defaultValuesForm = {
        [ForgotPasswordForm.email]: '',
    };

    const {
        handleSubmit: handleRecoveryPass,
        formState,
        control: controlRecoveryPass,
    } = useForm({
        resolver: recoveryPassResolver,
        mode: 'all',
        shouldUnregister: false,
        defaultValues: { ...defaultValuesForm },
    });

    return (
        <AuthFormForgotPassWrapper>
            <AuthFormForgotPassLabel>
                <Text fontSize={TextFontSize.XL} fontWeight={TextWeight.BOLD}>
                    {t('auth: recovery: title')}
                </Text>
            </AuthFormForgotPassLabel>
            <Text>{t('auth: recovery: desc')}</Text>
            <AuthFormForgotPassForm
                onSubmit={handleRecoveryPass(onRecoveryPassword)}
            >
                <AuthFormForgotPassFormItem>
                    <Controller
                        control={controlRecoveryPass}
                        name={ForgotPasswordForm.email}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                placeholder={t('auth: fields: email')}
                                isError={
                                    !!formState.errors[ForgotPasswordForm.email]
                                }
                                isVerified={
                                    (formState.isSubmitted ||
                                        formState.touchedFields?.[
                                            ForgotPasswordForm.email
                                        ]) &&
                                    !formState.errors[ForgotPasswordForm.email]
                                }
                                errorMessage={
                                    formState.errors[ForgotPasswordForm.email]
                                        ?.message
                                }
                            />
                        )}
                    />
                </AuthFormForgotPassFormItem>
                <Button isFullWidth>{t('auth: recovery: button')}</Button>
            </AuthFormForgotPassForm>
            <AuthFormForgotPassSignUpText>
                <AuthFormForgotPassSignUpTextItem>
                    <Text fontSize={TextFontSize.M}>
                        {t('auth: recovery: back_to')}
                    </Text>
                </AuthFormForgotPassSignUpTextItem>
                <AuthFormForgotPassSignUpTextItem>
                    <Text
                        isLink
                        fontWeight={TextWeight.BOLD}
                        color={TextColor.Link}
                        fontSize={TextFontSize.M}
                        onClick={onOpenRegistration}
                    >
                        {t('auth: sign_up: button')}
                    </Text>
                </AuthFormForgotPassSignUpTextItem>
            </AuthFormForgotPassSignUpText>
        </AuthFormForgotPassWrapper>
    );
};
