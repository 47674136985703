export const removeEmptyObjectFields = <T>(obj: T): T => {
    const result = {};
    Object.getOwnPropertyNames(obj).forEach((key) => {
        const value = obj[key];
        if (value !== undefined && value !== null) {
            result[key] = value;
        }
    });
    return result as T;
};
