import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { routes } from '@source/routes';
import { LayoutWrapper } from './layout.component.styles';
import { PageLayout } from '@source/components/layout/page-layout.component';
import { NotFoundPage } from '@source/page/not-found-page/not-found-page.component';
import { BusinessPage } from '@source/page/business-page/business-page.component';
import { useAutoScrollToTopWhenChangeLocation } from '@source/hooks/router-scroll-to-top.hook';

export const Layout: React.FC = () => {
    const location = useLocation();
    useAutoScrollToTopWhenChangeLocation();

    return (
        <LayoutWrapper>
            <Switch location={location}>
                <Route path={routes.notFound.path}>
                    <PageLayout>
                        <NotFoundPage />
                    </PageLayout>
                </Route>
                <Route path={routes.businessPage.path}>
                    <PageLayout isFooterExist={false}>
                        <BusinessPage />
                    </PageLayout>
                </Route>
                <Redirect to={routes.notFound.path} />
            </Switch>
        </LayoutWrapper>
    );
};
