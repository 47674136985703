import { Exception } from '@serviceplace/core';
import { LocaleStore } from '@source/store/locale.store';
import { LocaleApi } from '@source/api/locale.api';
import { LocaleModel } from '@source/data/model';

export class LocaleException extends Exception {
    constructor(reason: string) {
        super(`LocaleService error. Reason ${reason}`);
    }
}

export interface LocaleService {
    loadLocales(): Promise<LocaleModel[]>;
    loadCurrentLocaleCode(): Promise<string>;
}

export class LocaleServiceImpl implements LocaleService {
    constructor(
        private _localeStore: LocaleStore,
        private _localeApi: LocaleApi,
    ) {}

    async loadLocales(): Promise<LocaleModel[]> {
        try {
            const locales = await this._localeApi.loadLocales();
            this._localeStore.locales = locales;
            return locales;
        } catch (err) {
            throw new LocaleException(err);
        }
    }

    async loadCurrentLocaleCode(): Promise<string> {
        return this._localeApi.loadCurrentLocaleCode();
    }
}
