import styled from 'styled-components';

export const AuthFormSignUpTabWrapper = styled.div`
    margin-top: 20px;
`;

export const AuthFormSignUpTabSvg = styled.div`
    margin-right: 14px;
`;

export const AuthFormSignUpTabButton = styled.div`
    margin-bottom: 15px;
`;

export const AuthFormSignUpTabLabel = styled.div`
    margin-bottom: 8px;
`;

export const AuthFormSignUpTabEnterButtons = styled.div`
    margin-bottom: 20px;
`;

export const AuthFormSignUpTabForm = styled.form`
    margin-bottom: 25px;
`;

export const AuthFormSignUpTabFormItem = styled.div`
    margin-bottom: 15px;
`;

export const AuthFormSignUpTabCheckbox = styled.div`
    margin-bottom: 15px;
`;

export const AuthFormSignUpTabCheckboxes = styled.div`
    margin: 20px 0px;
`;

export const AuthFormSignUpTabFormButton = styled.div`
    margin-top: 20px;
`;

export const AuthFormSignUpTabText = styled.div`
    display: flex;
    justify-content: center;
    margin: 15px 0px;
`;

export const AuthFormSignUpTabTermsAgree = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const AuthFormSignUpTabTermsAgreeItem = styled.div`
    margin-right: 5px;
`;
