import { Converter } from '@serviceplace/core';
import { CountryDto } from '@source/data/dto';
import { CountryModel } from '@source/data/model';

export type CountryDtoToModelConverter = Converter<CountryDto, CountryModel>;

export class CountryDtoToModelConverterImpl
    extends Converter<CountryDto, CountryModel>
    implements CountryDtoToModelConverter {
    convert(source: CountryDto): CountryModel {
        return {
            countryCode: source.country_code,
            name: source.name,
            flag: source.flag,
            locale: source.locale,
            default: source.default,
            currencyIsoCode: source.currency?.iso_code ?? '',
            htmlEntity: source.html_entity,
            symbol: source.symbol,
        } as CountryModel;
    }
}
