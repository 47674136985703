import styled from 'styled-components';
import { Text } from '@autobooking/components';

export const HeaderAuthWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const HeaderAuthProfile = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const HeaderAuthProfileName = styled(Text)`
    padding: 10px;
`;

export const HeaderAuthProfileAvatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
`;

export const HeaderAuthDropdown = styled.div`
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    min-width: 200px;
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 1px 1px 5px 0 #ccc;
`;

export const HeaderAuthDropdownItem = styled.div`
    padding: 5px;
`;
