import styled, { css } from 'styled-components';
import { textFamilyMixin } from '@autobooking/components/src/component/text.component.styles';
import { TextFamily } from '@autobooking/components';

export const BusinessPageServicesBlockWrapper = styled.div`
    margin-top: 55px;
`;

export const BusinessPageServicesBlockLabel = styled.div`
    margin-bottom: 20px;
`;

export const BusinessPageServicesBlockGroupWrapper = styled.div`
    margin-bottom: 12px;
`;

export const BusinessPageServicesBlockGroupHeader = styled.div`
    display: flex;
    align-items: center;
`;

export const BusinessPageServicesBlockGroupBody = styled.div`
    margin: 14px 0px;
`;

export const BusinessPageServicesBlockGroupName = styled.div``;

type BusinessPageServicesBlockGroupButtonProps = {
    isOpen: boolean;
};

export const BusinessPageServicesBlockGroupButton = styled.div<
    BusinessPageServicesBlockGroupButtonProps
>`
    margin-left: 12px;
    padding: 0 18px;
    border-radius: 3px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: ${textFamilyMixin(TextFamily.BasisGrotesquePro)};
    ${({ theme, isOpen }) => css`
        color: ${isOpen
            ? theme.text.palette.reverse
            : theme.text.palette.label};
        border: 1px solid ${isOpen ? 'transparent' : theme.palette.Secondary};
        background-color: ${isOpen ? '#0e0f3e' : theme.palette.Reverse};
    `}
`;

export const BusinessPageServicesBlockGroupServiceWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 6px;
`;

export const BusinessPageServicesBlockGroupServiceLabel = styled.div`
    height: 30px;
    border-radius: 5px;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    ${({ theme }) => css`
        border: 1px dotted ${theme.palette.Secondary};
    `};
`;

export const BusinessPageServicesBlockGroupServiceLabelName = styled.div`
    margin-right: 15px;
`;

type BusinessPageServicesBlockGroupServiceButtonProps = {
    isHovered: boolean;
};

export const BusinessPageServicesBlockGroupServiceButton = styled.div<
    BusinessPageServicesBlockGroupServiceButtonProps
>`
    display: none;
    margin-left: 12px;
    padding: 0 18px;
    border-radius: 3px;
    height: 30px;
    cursor: pointer;
    font-family: ${textFamilyMixin(TextFamily.BasisGrotesquePro)};
    ${({ theme }) => css`
        color: ${theme.text.palette.reverse};
    `}
    background-color: #0e0f3e;
    ${({ isHovered }) => css`
        display: ${isHovered ? 'flex' : 'none'};
    `}
    align-items: center;
`;
