import * as React from 'react';
import { Image, Text, TextWeight } from '@autobooking/components';
import {
    HeaderAuthWrapper,
    HeaderAuthProfileName,
    HeaderAuthProfileAvatar,
    HeaderAuthDropdown,
    HeaderAuthDropdownItem,
    HeaderAuthProfile,
} from './header-auth.component.styles';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import { useStateObserver } from '@source/hooks/state-observer.hook';
import { UserInfoStore } from '@source/store/user-info.store';
import { AuthService } from '@source/service/auth.service';
import { AppTypes } from '@source/types';
import { UserRole } from '@source/data/enum';
import { useComponentVisible } from '@source/hooks/component-visible.hook';
import { APP_CONFIG_KEY } from '@source/config/app.config';

export const HeaderAuth = withDependencies(
    ({ $injected }) => {
        const [t] = AppCore.useTranslator(APP_CONFIG_KEY);

        const {
            refCurrentComponent,
            isComponentVisible,
            setIsComponentVisible,
        } = useComponentVisible(false);

        const [userInfoStore, authService] = $injected.dependencies as [
            UserInfoStore,
            AuthService,
        ];

        const { userProfile } = useStateObserver(
            () => ({
                userProfile: userInfoStore.userProfile,
            }),
            [],
        );

        const mainMenu = userProfileMenu
            .filter((it) => it.useRole.includes(userProfile?.role))
            .map((it) => ({
                title: t(it.title),
                link: it.link,
            }));

        const additionalMenu = userProfile?.helpLinks
            ? userProfile?.helpLinks?.map((it) => ({
                  title: it.title,
                  link: `/${it.slug}`,
              }))
            : [];

        const onLogout = async () => await authService.logout();

        return (
            <HeaderAuthWrapper>
                <HeaderAuthProfile
                    onClick={() => setIsComponentVisible(true)}
                    ref={refCurrentComponent}
                >
                    <HeaderAuthProfileName fontWeight={TextWeight.BOLD}>
                        {userProfile?.firstName}
                    </HeaderAuthProfileName>
                    <HeaderAuthProfileAvatar>
                        <Image src={userProfile?.avatarImageUrl} width="100%" />
                    </HeaderAuthProfileAvatar>
                    {isComponentVisible && (
                        <HeaderAuthDropdown>
                            {[...mainMenu, ...additionalMenu]?.map(
                                (menuItem, index) => (
                                    <HeaderAuthDropdownItem key={index}>
                                        <a href={menuItem.link}>
                                            <Text>{menuItem.title}</Text>
                                        </a>
                                    </HeaderAuthDropdownItem>
                                ),
                            )}
                            <HeaderAuthDropdownItem>
                                <Text onClick={onLogout}>
                                    {t(
                                        'insurances_front: header: auth: logout',
                                    )}
                                </Text>
                            </HeaderAuthDropdownItem>
                        </HeaderAuthDropdown>
                    )}
                </HeaderAuthProfile>
            </HeaderAuthWrapper>
        );
    },
    {
        dependencies: [AppTypes.UserInfoStore, AppTypes.AuthService],
    },
);

HeaderAuth.displayName = 'HeaderAuth';

const userProfileMenu = [
    {
        title: 'insurances_front: header: auth: menu: records',
        link: '/profile#orders',
        useRole: [
            UserRole.User,
            UserRole.Operator,
            UserRole.NightOperator,
            UserRole.LessNightOperator,
            UserRole.Moderator,
            UserRole.StationAdmin,
            UserRole.Bookkeeper,
            UserRole.StationManager,
        ],
    },
    {
        title: 'insurances_front: header: auth: menu: favorite_stations',
        link: '/profile#favorites',
        useRole: [
            UserRole.User,
            UserRole.Operator,
            UserRole.NightOperator,
            UserRole.LessNightOperator,
            UserRole.Moderator,
            UserRole.StationAdmin,
            UserRole.Bookkeeper,
            UserRole.StationManager,
        ],
    },
    {
        title: 'insurances_front: header: auth: menu: edit_profile',
        link: '/profile#profile',
        useRole: [
            UserRole.User,
            UserRole.Operator,
            UserRole.NightOperator,
            UserRole.LessNightOperator,
            UserRole.Moderator,
            UserRole.StationAdmin,
            UserRole.Bookkeeper,
            UserRole.StationManager,
        ],
    },
    {
        title: 'insurances_front: header: auth: menu: fines_payment',
        link: '/profile#fines',
        useRole: [
            UserRole.User,
            UserRole.Operator,
            UserRole.NightOperator,
            UserRole.LessNightOperator,
            UserRole.Moderator,
            UserRole.StationAdmin,
            UserRole.Bookkeeper,
            UserRole.StationManager,
        ],
    },
    {
        title: 'insurances_front: header: auth: menu: operator',
        link: '/station_admin/orders',
        useRole: [
            UserRole.Operator,
            UserRole.NightOperator,
            UserRole.LessNightOperator,
        ],
    },
    {
        title: 'insurances_front: header: auth: menu: moderator',
        link: '/station_admin/orders',
        useRole: [UserRole.Moderator],
    },
    {
        title: 'insurances_front: header: auth: menu: admin ',
        link: '/station_admin/stations',
        useRole: [UserRole.StationAdmin],
    },
    {
        title: 'insurances_front: header: auth: menu: manager',
        link: '/station_admin/stations',
        useRole: [UserRole.StationManager],
    },
    {
        title: 'insurances_front: header: auth: menu: accountant',
        link: '/station_admin/invoices/balance_history',
        useRole: [UserRole.Bookkeeper],
    },
];
