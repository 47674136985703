import { Exception } from '@serviceplace/core';
import { UserInfoStore } from '@source/store/user-info.store';
import {
    AuthApi,
    AuthRegistrationType,
    AuthUserCredential,
} from '@source/api/auth.api';
import { UserModel } from '@source/data/model';
import { CheckoutStore } from '@source/store/checkout.store';
import { SearchApi } from '@source/api/search.api';
import { UserDocumentType } from '@source/data/enum/user-document-type.enum';

export class AuthException extends Exception {
    constructor(reason: string) {
        super(`AuthException error. Reason ${reason}`);
    }
}

export interface AuthService {
    initAuthUser(): Promise<void>;
    login({ email, password }: AuthUserCredential): Promise<void>;
    googleAuth(accessToken: string): Promise<void>;
    facebookAuth(accessToken: string): Promise<void>;
    registration({
        email,
        password,
        promoNotifyAgree,
    }: AuthRegistrationType): Promise<void>;
    logout(): Promise<void>;
}

export class AuthServiceImpl implements AuthService {
    constructor(
        private _userInfoStore: UserInfoStore,
        private _authApi: AuthApi,
        private _checkoutStore: CheckoutStore,
        private _searchApi: SearchApi,
    ) {}

    clearAuthData = (): void => {
        this._userInfoStore.userProfile = null;
        this._userInfoStore.isAuthorized = false;
    };

    setAuthData = async (userProfile: UserModel): Promise<void> => {
        this._userInfoStore.userProfile = userProfile;
        this._userInfoStore.isAuthorized = true;
        await this.setDefaultCustomerDataByProfile(userProfile);
    };

    setDefaultCustomerDataByProfile = async (
        userProfile: UserModel,
    ): Promise<void> => {
        if (userProfile.email) {
            this._checkoutStore.customerData.email = userProfile.email;
        }
        if (userProfile.firstName) {
            this._checkoutStore.customerData.nameFirst = userProfile.firstName;
        }
        if (userProfile.lastName) {
            this._checkoutStore.customerData.nameLast = userProfile.email;
        }
        if (userProfile.phone) {
            this._checkoutStore.customerData.phone = userProfile.phone;
        }
        if (userProfile?.documents.length > 0) {
            const userDocuments = userProfile.documents.reduce((acc, value) => {
                acc[value.type] = { ...value };
                if (value.type === UserDocumentType.License) {
                    this._checkoutStore.customerData.licenseDocumentSeries =
                        value.series || '';
                    this._checkoutStore.customerData.licenseDocumentNumber =
                        value.number?.toString() || '';
                }
                if (value.type === UserDocumentType.IdCard) {
                    this._checkoutStore.customerData.idCardDocumentNumber =
                        value.number?.toString() || '';
                }
                if (value.type === UserDocumentType.Passport) {
                    this._checkoutStore.customerData.passportDocumentSeries =
                        value.series || '';
                    this._checkoutStore.customerData.passportDocumentNumber =
                        value.number?.toString() || '';
                }
                return acc;
            }, {});

            let mainDocType: UserDocumentType = null;
            if (userDocuments[UserDocumentType.License])
                mainDocType = UserDocumentType.License;
            if (userDocuments[UserDocumentType.IdCard])
                mainDocType = UserDocumentType.IdCard;
            if (userDocuments[UserDocumentType.Passport])
                mainDocType = UserDocumentType.Passport;

            if (mainDocType) {
                this._checkoutStore.customerData.documentType = mainDocType;
            }
        }
        if (userProfile.cars?.length > 0) {
            const { registrationNumber } = userProfile.cars[
                userProfile.cars.length - 1
            ];

            if (registrationNumber) {
                this._checkoutStore.vehicleData.stateNumber = registrationNumber;

                try {
                    const vehicleResponse = await this._searchApi.searchVehicle(
                        registrationNumber,
                    );

                    if (vehicleResponse?.length > 0) {
                        const bodyNumber =
                            vehicleResponse[0].vehicle.bodyNumber;
                        const brandModel =
                            vehicleResponse[0].vehicle.model.name;
                        const brandId =
                            vehicleResponse[0].vehicle.model.autoMaker.name;

                        if (brandId) {
                            this._checkoutStore.vehicleData.brandId = brandId;
                        }
                        if (brandModel) {
                            this._checkoutStore.vehicleData.brandModel = brandModel;
                        }
                        if (bodyNumber) {
                            this._checkoutStore.vehicleData.bodyNumber = bodyNumber;
                        }
                    }
                } catch (e) {}
            }
        }
    };

    async initAuthUser(): Promise<void> {
        try {
            await this.setAuthData(await this._authApi.getUser());
        } catch (error) {
            this.clearAuthData();
            throw new AuthException(error);
        }
    }

    async googleAuth(accessToken: string): Promise<void> {
        try {
            await this.setAuthData(await this._authApi.googleAuth(accessToken));
        } catch (error) {
            throw new AuthException(error);
        }
    }

    async facebookAuth(accessToken: string): Promise<void> {
        try {
            await this.setAuthData(
                await this._authApi.facebookAuth(accessToken),
            );
        } catch (error) {
            throw new AuthException(error);
        }
    }

    async registration(authData: AuthRegistrationType): Promise<void> {
        try {
            await this._authApi.registration(authData);
        } catch (error) {
            throw new AuthException(error);
        }
    }

    async login(authData: AuthUserCredential): Promise<void> {
        try {
            await this.setAuthData(await this._authApi.login(authData));
        } catch (error) {
            throw new AuthException(error);
        }
    }

    async logout(): Promise<void> {
        try {
            await this._authApi.logout();
            this.clearAuthData();
        } catch (error) {
            throw new AuthException(error);
        }
    }
}
