import { SettingsModel } from '@source/data/model';
import { AppConfig } from '@source/config/app.config';
import { ServerResponseDto, SettingsDto } from '@source/data/dto';
import { SettingsDtoToModelConverter } from '@source/converter/settings-dto-to-model.converter';
import { InternationalizationStore } from '@serviceplace/core';

export interface SettingsApi {
    loadSettings(country: string): Promise<SettingsModel>;
}

export class SettingsApiImpl implements SettingsApi {
    constructor(
        private _appConfig: AppConfig,
        private _internationalizationStore: InternationalizationStore,
        private _settingsConverter: SettingsDtoToModelConverter,
    ) {}

    async loadSettings(country: string): Promise<SettingsModel> {
        const url = `${this._appConfig.host}/api/insurances/v1/country_settings/${country}`;
        const res: Response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept-Language': this._internationalizationStore
                    .currentLocale,
            },
        });
        const resData: ServerResponseDto<SettingsDto> = await res.json();

        return this._settingsConverter.convert(resData.data);
    }
}
