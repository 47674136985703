import { observable, makeObservable } from 'mobx';
import dayjs from 'dayjs';
import { UserDocumentType } from '@source/data/enum/user-document-type.enum';

export enum ModalStep {
    Customer = 'Customer',
    CarData = 'CarData',
}

export enum CheckoutStep {
    Customer = 'Customer',
    CarData = 'CarData',
    CheckData = 'CheckData',
    Checkout = 'Checkout',
}

export enum VehicleField {
    BrandId = 'brandId',
    BrandModel = 'brandModel',
    Year = 'year',
    EngineVolume = 'engineVolume',
    BodyNumber = 'bodyNumber',
    StateNumber = 'stateNumber',
    DateFrom = 'dateFrom',
    IsDgoActive = 'isDgoActive',
    DgoValue = 'dgoValue',
    IsResetFranchise = 'isResetFranchise',
    IsAccidentInsurance = 'isAccidentInsurance',
    CreateAccount = 'createAccount',
    Password = 'password',
    Email = 'email',
}

export type VehicleData = {
    [VehicleField.BrandId]?: string;
    [VehicleField.BrandModel]?: string;
    [VehicleField.Year]?: string;
    [VehicleField.EngineVolume]?: string;
    [VehicleField.BodyNumber]?: string;
    [VehicleField.StateNumber]?: string;
    [VehicleField.DateFrom]?: Date;
    [VehicleField.IsDgoActive]?: boolean;
    [VehicleField.DgoValue]?: string;
    [VehicleField.IsResetFranchise]?: boolean;
    [VehicleField.IsAccidentInsurance]?: boolean;
    [VehicleField.CreateAccount]?: boolean;
    [VehicleField.Password]?: string;
    [VehicleField.Email]?: string;
};

export enum CustomerField {
    Phone = 'phone',
    Email = 'email',
    NameFirst = 'nameFirst',
    NameLast = 'nameLast',
    NameMiddle = 'nameMiddle',
    BirthDate = 'birthDate',
    Code = 'code',
    CityCode = 'cityCode',
    Street = 'street',
    HouseNumber = 'houseNumber',
    ApartmentNumber = 'apartmentNumber',
    DocumentType = 'documentType',
    LicenseDocumentSeries = 'licenseDocumentSeries',
    LicenseDocumentNumber = 'licenseDocumentNumber',
    LicenseDocumentIssuedBy = 'licenseDocumentIssuedBy',
    LicenseDocumentDate = 'licenseDocumentDate',
    PassportDocumentSeries = 'passportDocumentSeries',
    PassportDocumentNumber = 'passportDocumentNumber',
    PassportDocumentIssuedBy = 'passportDocumentIssuedBy',
    PassportDocumentDate = 'passportDocumentDate',
    IdCardDocumentNumber = 'idCardDocumentNumber',
    IdCardDocumentIssuedBy = 'idCardDocumentIssuedBy',
    IdCardDocumentDate = 'idCardDocumentDate',
    AgreeRules = 'agreeRules',
}

export type CustomerData = {
    [CustomerField.Phone]?: string;
    [CustomerField.Email]?: string;
    [CustomerField.NameFirst]?: string;
    [CustomerField.NameLast]?: string;
    [CustomerField.NameMiddle]?: string;
    [CustomerField.BirthDate]?: Date;
    [CustomerField.Code]?: string;
    [CustomerField.CityCode]?: string;
    [CustomerField.Street]?: string;
    [CustomerField.HouseNumber]?: string;
    [CustomerField.ApartmentNumber]?: string;
    [CustomerField.DocumentType]?: UserDocumentType;
    [CustomerField.LicenseDocumentSeries]?: string;
    [CustomerField.LicenseDocumentNumber]?: string;
    [CustomerField.LicenseDocumentIssuedBy]?: string;
    [CustomerField.LicenseDocumentDate]?: Date;
    [CustomerField.PassportDocumentSeries]?: string;
    [CustomerField.PassportDocumentNumber]?: string;
    [CustomerField.PassportDocumentIssuedBy]?: string;
    [CustomerField.PassportDocumentDate]?: Date;
    [CustomerField.IdCardDocumentNumber]?: string;
    [CustomerField.IdCardDocumentIssuedBy]?: string;
    [CustomerField.IdCardDocumentDate]?: Date;
    [CustomerField.AgreeRules]?: boolean;
};

export interface CheckoutStore {
    checkoutStep: CheckoutStep;
    modalStep: ModalStep;
    customerData: CustomerData;
    vehicleData: VehicleData;
}

export class CheckoutStoreImpl implements CheckoutStore {
    public checkoutStep: CheckoutStep = CheckoutStep.Customer;
    public modalStep: ModalStep = ModalStep.Customer;
    public customerData: CustomerData = {
        phone: '',
        email: '',
        nameFirst: '',
        nameLast: '',
        nameMiddle: '',
        birthDate: null,
        code: '',
        cityCode: '',
        street: '',
        houseNumber: '',
        apartmentNumber: '',
        documentType: UserDocumentType.Passport,
        licenseDocumentSeries: '',
        licenseDocumentNumber: '',
        licenseDocumentIssuedBy: '',
        licenseDocumentDate: null,
        passportDocumentSeries: '',
        passportDocumentNumber: '',
        passportDocumentIssuedBy: '',
        passportDocumentDate: null,
        idCardDocumentNumber: '',
        idCardDocumentIssuedBy: '',
        idCardDocumentDate: null,
        agreeRules: false,
    };
    public vehicleData: VehicleData = {
        brandId: '',
        brandModel: '',
        year: '',
        engineVolume: '',
        bodyNumber: '',
        stateNumber: '',
        dateFrom: dayjs().add(1, 'day').toDate(),
        isDgoActive: false,
        dgoValue: '',
        isResetFranchise: false,
        isAccidentInsurance: false,
        createAccount: false,
        password: '',
        email: '',
    };

    constructor() {
        makeObservable(this, {
            checkoutStep: observable,
            modalStep: observable,
            customerData: observable,
            vehicleData: observable,
        });
    }
}
