import { CityModel } from '@source/data/model';
import { AppConfig } from '@source/config/app.config';
import { GeoPositionDto, ServerResponseDto } from '@source/data/dto';
import { GeoPositionDtoToModelConverter } from '@source/converter/geo-position-dto-to-city-model.converter';
import { InternationalizationStore } from '@serviceplace/core';

export interface GeoPositionApi {
    loadGeoPosition(lat?: string, lng?: string): Promise<CityModel>;
}

export class GeoPositionApiImpl implements GeoPositionApi {
    constructor(
        private _appConfig: AppConfig,
        private _internationalizationStore: InternationalizationStore,
        private _geoPositionConverter: GeoPositionDtoToModelConverter,
    ) {}

    async loadGeoPosition(lat?: string, lng?: string): Promise<CityModel> {
        const url = `${this._appConfig.host}/api/insurances/v1/geocode${
            lat && lng ? `?lat=${lat}&lng=${lng}` : ''
        }`;
        const res: Response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept-Language': this._internationalizationStore
                    .currentLocale,
            },
        });
        const resData: ServerResponseDto<GeoPositionDto> = await res.json();

        return this._geoPositionConverter.convert(resData.data);
    }
}
