export enum UserRole {
    User = 'user',
    Operator = 'operator',
    NightOperator = 'night_operator',
    LessNightOperator = 'less_night_operator',
    Moderator = 'moderator',
    StationAdmin = 'station_admin',
    Bookkeeper = 'bookkeeper',
    StationManager = 'station_manager',
}
