import { Converter } from '@serviceplace/core';
import { GeoPositionDto } from '@source/data/dto';
import { CityModel } from '@source/data/model';

export type GeoPositionDtoToModelConverter = Converter<
    GeoPositionDto,
    CityModel
>;

export class GeoPositionDtoToModelConverterImpl
    extends Converter<GeoPositionDto, CityModel>
    implements GeoPositionDtoToModelConverter {
    convert(source: GeoPositionDto): CityModel {
        return {
            zone: source.city_data.zone,
            slug: source.city_data.slug,
            label: source.city_data.nameFull,
            valueCode: source.city_data.placeCode,
            mtsbuCode: String(source.city_data.cdbMtibuCode),
            value: String(source.city_data.id),
            name: source.city_data.name,
        } as CityModel;
    }
}
