import styled from 'styled-components';

export const FooterWrapper = styled.div`
    width: 100%;
    background-color: #373b3e;
    padding: 75px 0 30px;
`;

export const FooterCopyright = styled.div`
    margin-top: 20px;
    text-align: center;
`;

export const FooterContentItem = styled.div`
    padding-bottom: 14px;
`;

export const FooterContentTitle = styled.div`
    padding-bottom: 24px;
`;

export const FooterApplications = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`;

export const FooterSocialLinks = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

export const FooterSocialLinksItem = styled.a`
    display: flex;
    align-items: center;
    font-size: 24px;
    margin: 0 20px 20px 0;
`;
