import { Converter } from '@serviceplace/core';
import { SettingsDto } from '@source/data/dto';
import { SettingsModel } from '@source/data/model';

export type SettingsDtoToModelConverter = Converter<SettingsDto, SettingsModel>;

export class SettingsDtoToModelConverterImpl
    extends Converter<SettingsDto, SettingsModel>
    implements SettingsDtoToModelConverter {
    convert(source: SettingsDto): SettingsModel {
        return {
            contacts: {
                hotLine: source?.contacts?.hot_line,
                hotLineWorkingTime: source?.contacts?.hot_line_working_time,
                address: source?.contacts?.address,
                email: source?.contacts?.email_user,
                skype: source?.contacts?.skype,
                latitude: source?.contacts?.latitude,
                longitude: source?.contacts?.longitude,
                showPhoneInHeader: source?.contacts?.show_phone_in_header,
            },
            mobileApps: source.mobile_apps,
            socialLinks: source.social,
            footerMenu: source.footer_menu,
            headerMenu: source.header_menu,
        } as SettingsModel;
    }
}
