import { observable, makeObservable } from 'mobx';
import { CountryModel } from '@source/data/model';

export interface CountryStore {
    selectedCountry: CountryModel;
    countries: CountryModel[];
}

export class CountryStoreImpl implements CountryStore {
    public selectedCountry: CountryModel = null;
    public countries: CountryModel[] = [];

    constructor() {
        makeObservable(this, {
            selectedCountry: observable,
            countries: observable,
        });
    }
}
