import { Exception } from '@serviceplace/core';
import { CountryStore } from '@source/store/country.store';
import { CountryApi } from '@source/api/country.api';
import { CountryModel } from '@source/data/model';

export class CountryException extends Exception {
    constructor(reason: string) {
        super(`CountryService error. Reason ${reason}`);
    }
}

export interface CountryService {
    loadCountries(): Promise<CountryModel[]>;
}

export class CountryServiceImpl implements CountryService {
    constructor(
        private _countryStore: CountryStore,
        private _countryApi: CountryApi,
    ) {}

    async loadCountries(): Promise<CountryModel[]> {
        try {
            const countries = await this._countryApi.loadCountries();
            this._countryStore.countries = countries;
            if (!this._countryStore.selectedCountry) {
                const defaultSelectedCountry = countries.find(
                    (it) => it.default,
                );
                if (!!defaultSelectedCountry) {
                    this._countryStore.selectedCountry = defaultSelectedCountry;
                }
            }
            return countries;
        } catch (err) {
            throw new CountryException(err);
        }
    }
}
