import React from 'react';
import {
    ModalControllerImpl,
    ModuleContext,
    ToastControllerImpl,
    useStateObserver
} from '@serviceplace/core';
import { ThemeProvider } from 'styled-components';
import {
    defaultTheme,
    FontStyle,
    GlobalStyle,
    ResetStyle,
    SvgSprite,
} from '@autobooking/components';
import { BrowserRouter } from 'react-router-dom';
import { AppModules, AppTypes } from '@source/types';
import { Layout } from '@source/components/layout/layout.component';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import { SettingsService } from '@source/service/settings.service';
import { CountryStore } from '@source/store/country.store';
import {
    userGeoPositionCityKey,
    UserInfoService,
} from '@source/service/user-info.service';
import { GeoPositionService } from '@source/service/geo-position.service';

export const App = withDependencies(
    ({ $injected }) => {
        const [
            settingService,
            countryStore,
            userInfoService,
            geoPositionService,
        ] = $injected.dependencies as [
            SettingsService,
            CountryStore,
            UserInfoService,
            GeoPositionService,
        ];
        const { currentLocale } = AppCore.useI18n();
        const userGeoPositionCity = localStorage.getItem(
            userGeoPositionCityKey,
        );

        const { currentCountry } = useStateObserver(
            () => ({ currentCountry: countryStore.selectedCountry }),
            [],
        );

        React.useEffect(() => {
            userInfoService.initialValues();
        }, []);

        React.useEffect(() => {
            const fetchSettings = async () => {
                await settingService.loadSettings(currentCountry.countryCode);
            };

            fetchSettings();
        }, [currentCountry, currentLocale]);

        React.useEffect(() => {
            !userGeoPositionCity &&
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const lat = String(position.coords.latitude);
                        const lng = String(position.coords.longitude);

                        geoPositionService
                            .loadGeoPosition(lat, lng)
                            .then((city) => {
                                userInfoService.setUserCity(city);
                            });
                    },
                    () => {
                        geoPositionService.loadGeoPosition().then((city) => {
                            userInfoService.setUserCity(city);
                        });
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 5000,
                    },
                );
        }, []);

        return (
            <BrowserRouter>
                <ModuleContext.Provider value={AppModules.Self}>
                    <ThemeProvider theme={defaultTheme}>
                        <ModalControllerImpl />
                        <ToastControllerImpl />
                        <SvgSprite />
                        <FontStyle />
                        <ResetStyle />
                        <GlobalStyle />
                        <Layout />
                    </ThemeProvider>
                </ModuleContext.Provider>
            </BrowserRouter>
        );
    },
    {
        dependencies: [
            AppTypes.SettingsService,
            AppTypes.CountryStore,
            AppTypes.UserInfoService,
            AppTypes.GeoPositionService,
        ],
    },
);

App.displayName = 'App';
