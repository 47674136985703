import * as React from 'react';
import { Tabs } from '@autobooking/components';
import { AuthFormWrapper } from './auth-form.component.styles';
import {
    AuthFormSignInTab,
    ModalFormSignInData,
} from './tabs/auth-form-sign-in-tab.component';
import {
    AuthFormSignUpTab,
    ModalFormSignUpData,
} from './tabs/auth-form-sign-up-tab.component';
import { APP_CONFIG_KEY } from '@source/config/app.config';
import {
    AuthFormForgotPass,
    ForgotPasswordFormProps,
} from './auth-form-forgot-pass.component';
import { useEffect } from 'react';
import { AppTypes } from '@source/types';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import { AuthService } from '@source/service/auth.service';

export enum AuthFormTabKey {
    SignUp = 'SignUp',
    SignIn = 'SignIn',
}

export enum AuthFormStatus {
    Registration = 'registration',
    Login = 'login',
    GoogleAuth = 'googleAuth',
    FacebookAuth = 'facebookAuth',
}

export type AuthFormProps = {
    defaultTab: AuthFormTabKey;
    onAuthSuccess: (status?: AuthFormStatus, email?: string) => void;
};

export const AuthForm: React.FC<AuthFormProps> = withDependencies(
    ({ $injected, defaultTab, onAuthSuccess }) => {
        const [t] = AppCore.useTranslator(APP_CONFIG_KEY);
        const [activeTabKey, setActiveTabKey] = React.useState<string>(
            defaultTab,
        );
        const [isForgotPassword, setIsForgotPassword] = React.useState(false);

        const [authService] = $injected.dependencies as [AuthService];

        useEffect(() => {
            setActiveTabKey(defaultTab);
        }, [defaultTab]);

        const onOpenRegistrationTab = () => {
            setIsForgotPassword(false);
            setActiveTabKey(AuthFormTabKey.SignUp);
        };

        const onSignIn = async ({ email, password }: ModalFormSignInData) => {
            try {
                await authService.login({ email, password });
                onAuthSuccess?.(AuthFormStatus.Login);
            } catch (e) {
                console.error(e);
            }
        };

        const onGoogleAuth = async (accessToken: string) => {
            try {
                await authService.googleAuth(accessToken);
                onAuthSuccess?.(AuthFormStatus.GoogleAuth);
            } catch (e) {
                console.error(e);
            }
        };

        const onFacebookAuth = async (accessToken: string) => {
            try {
                await authService.facebookAuth(accessToken);
                onAuthSuccess?.(AuthFormStatus.FacebookAuth);
            } catch (e) {
                console.error(e);
            }
        };

        const onSingUp = async ({
            email,
            password,
            promo,
            terms,
        }: ModalFormSignUpData) => {
            if (terms) {
                try {
                    await authService.registration({
                        email,
                        password,
                        promoNotifyAgree: promo,
                    });
                    onAuthSuccess?.(AuthFormStatus.Registration, email);
                } catch (e) {
                    console.error(e);
                }
            }
        };

        const onRecoveryPassword = ({ email }: ForgotPasswordFormProps) => {
            //TODO Add back & front RecoveryPassword realization
            console.log('onRecoveryPassword email:', email);
        };

        const tabs = [
            {
                key: AuthFormTabKey.SignIn,
                label: t('auth: sign_in: title'),
                content: (
                    <AuthFormSignInTab
                        onOpenForgotPassword={() => setIsForgotPassword(true)}
                        onOpenRegistration={onOpenRegistrationTab}
                        onSignIn={onSignIn}
                        onGoogleAuth={onGoogleAuth}
                        onFacebookAuth={onFacebookAuth}
                    />
                ),
            },
            {
                key: AuthFormTabKey.SignUp,
                label: t('auth: sign_up: title'),
                content: (
                    <AuthFormSignUpTab
                        onGoogleAuth={onGoogleAuth}
                        onFacebookAuth={onFacebookAuth}
                        onSignUp={onSingUp}
                    />
                ),
            },
        ];

        return (
            <AuthFormWrapper>
                {!isForgotPassword && (
                    <Tabs
                        activeTabKey={activeTabKey}
                        onChangeActiveTab={setActiveTabKey}
                        tabs={tabs}
                        isFullWidth
                    >
                        {(controller, tab) => (
                            <>
                                {controller}
                                {tab?.content ?? null}
                            </>
                        )}
                    </Tabs>
                )}
                {isForgotPassword && (
                    <AuthFormForgotPass
                        onOpenRegistration={onOpenRegistrationTab}
                        onRecoveryPassword={onRecoveryPassword}
                    />
                )}
            </AuthFormWrapper>
        );
    },
    {
        dependencies: [AppTypes.AuthService],
    },
);

AuthForm.displayName = 'AuthForm';

AuthForm.defaultProps = {
    defaultTab: AuthFormTabKey.SignIn,
};
