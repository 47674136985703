import styled from 'styled-components';

export const NotFoundPageWrapper = styled.div`
    background: #f2f4f8;
    position: relative;
`;

export const NotFoundPageContent = styled.div`
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;
