import * as React from 'react';
import { CommonProps } from '@serviceplace/core';
import {
    BusinessPageWrapper,
    BusinessPageContent,
    BusinessPageBusinessContent,
    BusinessPageOrderContent,
    BusinessPageInfoBlock,
    BusinessPageInfoBlockAvatar,
    BusinessPageInfoBlockGridSecondRow,
    BusinessPageInfoBlockGridThirdRow,
    BusinessPageInfoBlockAddress,
    BusinessPageInfoBlockAddressText,
    BusinessPageInfoBlockRating,
    BusinessPageInfoBlockRatingText,
    BusinessPageInfoBlockReviews,
    BusinessPageInfoBlockPartners,
    BusinessPageInfoBlockPartnersImage,
    BusinessPageCarousel,
    BusinessPageAdditionalInfoBlock,
    BusinessPageAdditionalInfoBlockAmenities,
    BusinessPageAdditionalInfoBlockAmenitiesItems,
    BusinessPageAdditionalInfoBlockAmenitiesItem,
    BusinessPageAdditionalInfoBlockAmenitiesItemIcon,
    BusinessPageAdditionalInfoBlockShedule,
    BusinessPageAdditionalInfoBlockSheduleRow,
    BusinessPageAdditionalInfoBlockAwards,
    BusinessPageAdditionalInfoBlockAward,
    BusinessPageAdditionalInfoBlockAwardText,
    BusinessPageBrands,
    BusinessPageBrandsTitle,
    BusinessPageBrandsTitleButton,
    BusinessPageBrandsTitleButtonIcon,
    BusinessPageBrandsList,
    BusinessPageBrandsListItem,
    BusinessPageBrandsListItemText,
    BusinessPageDescription,
    BusinessPageDescriptionHeader,
    BusinessPageRating,
    BusinessPageTotalRating,
} from './business-page.component.styles';
import {
    Text,
    TextFontSize,
    TextWeight,
    TextColor,
    WorkTime,
    Row,
    Col,
    Bookmark,
    MarkerSvg,
    StarSvg,
    Carousel,
    Image,
    InstagramSvg,
    CaretSvg,
    Rating,
} from '@autobooking/components';
import { Breadcrumb } from '@source/components/breadcrumb.component';
import { BusinessPageServicesBlock } from './business-page-services-block.component';
import avatar from '../../img/avatar.png';
import liquimoly from '../../img/liquimoly.png';
import bosch from '../../img/bosch.png';
import michelin from '../../img/michelin.png';
import sto1 from '../../img/sto1.png';
import sto2 from '../../img/sto2.png';
import sto3 from '../../img/sto3.png';
import award1 from '../../img/award1.png';
import award2 from '../../img/award2.png';
import bmwLogo from '../../img/bmw-logo.png';

export type BusinessPageProps = CommonProps;

export const BusinessPage: React.FC<BusinessPageProps> = ({
    ...commonProps
}) => {
    const [isBrandListOpen, setIsBrandListOpen] = React.useState(false);

    const handleBrandListClick = () => setIsBrandListOpen(!isBrandListOpen);

    const breadcrumbLinks = [
        { label: 'RST Buchach', link: '' },
        { label: 'Wheel Repair', link: '' },
    ];

    const partnerImages = [liquimoly, bosch, michelin];
    const carouselImages = [sto1, sto2, sto3, sto1, sto2, sto3];

    const amenities = [
        { label: 'toilet', icon: <InstagramSvg fill="#0e0f3" height="13px" /> },
        { label: 'wifi', icon: <InstagramSvg fill="#0e0f3" height="13px" /> },
        { label: 'toilet', icon: <InstagramSvg fill="#0e0f3" height="13px" /> },
        { label: 'wifi', icon: <InstagramSvg fill="#0e0f3" height="13px" /> },
        { label: 'toilet', icon: <InstagramSvg fill="#0e0f3" height="13px" /> },
        { label: 'wifi', icon: <InstagramSvg fill="#0e0f3" height="13px" /> },
        { label: 'toilet', icon: <InstagramSvg fill="#0e0f3" height="13px" /> },
        { label: 'wifi', icon: <InstagramSvg fill="#0e0f3" height="13px" /> },
        { label: 'toilet', icon: <InstagramSvg fill="#0e0f3" height="13px" /> },
        { label: 'wifi', icon: <InstagramSvg fill="#0e0f3" height="13px" /> },
    ];

    const awards = [
        { label: 'Best auto mechanic 2016', img: award1 },
        { label: 'Best RST mechanic 2016', img: award2 },
    ];

    const brandsList = [
        { label: 'Aston Martin', img: bmwLogo },
        { label: 'BMW', img: bmwLogo },
        { label: 'Renault', img: bmwLogo },
        { label: 'Lexus', img: bmwLogo },
        { label: 'Skoda', img: bmwLogo },
        { label: 'Porsche', img: bmwLogo },
        { label: 'Suzuki', img: bmwLogo },
        { label: 'Volkswagen', img: bmwLogo },
        { label: 'Mercedes-Benz', img: bmwLogo },
        { label: 'Mazda', img: bmwLogo },
        { label: 'Alfa-Romeo', img: bmwLogo },
        { label: 'Subaru', img: bmwLogo },
        { label: 'Lamborghini', img: bmwLogo },
        { label: 'Tesla', img: bmwLogo },
        { label: 'Toyota', img: bmwLogo },
        { label: 'Audi', img: bmwLogo },
        { label: 'Rolls-Royce', img: bmwLogo },
        { label: 'Bentley', img: bmwLogo },
        { label: 'Maserati', img: bmwLogo },
        { label: 'Pagani', img: bmwLogo },
        { label: 'Bugatti', img: bmwLogo },
        { label: 'Ferrari', img: bmwLogo },
        { label: 'Chevrolet', img: bmwLogo },
        { label: 'Ford', img: bmwLogo },
        { label: 'Dodge', img: bmwLogo },
    ];

    const services = [
        {
            name: 'Replacement of engine mount',
            servicesCount: 2,
            servicesList: [
                { name: 'Hood crack', price: '50$' },
                { name: 'Wheel repair', price: '150$' },
            ],
        },
        {
            name: 'Replacement of starter',
            servicesCount: 2,
            servicesList: [
                { name: 'Hood crack', price: '50$' },
                { name: 'Wheel repair', price: '150$' },
            ],
        },
    ];

    const rating = {
        totalRating: { name: 'RST Rating', rating: '5.0' },
        categories: [
            { name: 'Clean', rating: '4.0' },
            { name: 'Service', rating: '5.0' },
            { name: 'Location', rating: '5.0' },
            {
                name: 'Diagnostic',
                rating: '4.9',
                subcategories: [
                    { name: 'Complete diagnosis', rating: '5.0' },
                    { name: 'Repair of injectors', rating: '4.5' },
                ],
            },
        ],
    };

    return (
        <BusinessPageWrapper {...commonProps}>
            <BusinessPageContent>
                <BusinessPageBusinessContent>
                    <Breadcrumb
                        links={breadcrumbLinks}
                        color={TextColor.Label}
                    />
                    <BusinessPageInfoBlock>
                        <Row>
                            <Col xs={8} xsOffset={3}>
                                <WorkTime
                                    isOpen={true}
                                    description="open on 20:00"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <BusinessPageInfoBlockGridSecondRow>
                                    <BusinessPageInfoBlockAvatar src={avatar} />
                                </BusinessPageInfoBlockGridSecondRow>
                            </Col>
                            <Col xs={17}>
                                <BusinessPageInfoBlockGridSecondRow>
                                    <Text
                                        fontSize="36px"
                                        fontWeight={TextWeight.BOLD}
                                    >
                                        RST DzigoraComplexSecond
                                    </Text>
                                </BusinessPageInfoBlockGridSecondRow>
                            </Col>
                            <Col xs={4}>
                                <BusinessPageInfoBlockGridSecondRow>
                                    <Bookmark description="add station to favorite" />
                                </BusinessPageInfoBlockGridSecondRow>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={16} xsOffset={3}>
                                <BusinessPageInfoBlockGridThirdRow>
                                    <BusinessPageInfoBlockAddress>
                                        <MarkerSvg size="13px" />
                                        <BusinessPageInfoBlockAddressText>
                                            <Text
                                                fontSize={TextFontSize.M}
                                                color={TextColor.Label}
                                                isLink
                                            >
                                                1.1 km Shalett street, Kyiv
                                            </Text>
                                        </BusinessPageInfoBlockAddressText>
                                    </BusinessPageInfoBlockAddress>
                                    <BusinessPageInfoBlockRating>
                                        <BusinessPageInfoBlockRatingText>
                                            <Text
                                                fontSize={TextFontSize.M}
                                                color="#f8d842"
                                            >
                                                5.0
                                            </Text>
                                        </BusinessPageInfoBlockRatingText>
                                        <StarSvg size="16px" />
                                    </BusinessPageInfoBlockRating>
                                    <BusinessPageInfoBlockReviews>
                                        <Text
                                            fontSize={TextFontSize.M}
                                            color={TextColor.Label}
                                            isLink
                                        >
                                            2 reviews
                                        </Text>
                                    </BusinessPageInfoBlockReviews>
                                </BusinessPageInfoBlockGridThirdRow>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={16} xsOffset={3}>
                                <BusinessPageInfoBlockPartners>
                                    <Text
                                        fontSize={TextFontSize.S}
                                        color={TextColor.Label}
                                    >
                                        official partner
                                    </Text>
                                    {partnerImages.map((item, index) => (
                                        <BusinessPageInfoBlockPartnersImage
                                            src={item}
                                            key={index}
                                        />
                                    ))}
                                </BusinessPageInfoBlockPartners>
                            </Col>
                        </Row>
                    </BusinessPageInfoBlock>
                    <BusinessPageAdditionalInfoBlock>
                        <Row>
                            <BusinessPageCarousel>
                                <Carousel
                                    dots={false}
                                    slidesToShow={3}
                                    infinite
                                >
                                    {carouselImages.map((item, index) => (
                                        <Image
                                            src={item}
                                            width="100%"
                                            height="133px"
                                            key={index}
                                        />
                                    ))}
                                </Carousel>
                            </BusinessPageCarousel>
                        </Row>
                        <Row>
                            <BusinessPageAdditionalInfoBlockAmenities>
                                <Text
                                    fontSize={TextFontSize.M}
                                    color={TextColor.Main}
                                >
                                    The following amenities will be available to
                                    you:
                                </Text>
                                <BusinessPageAdditionalInfoBlockAmenitiesItems>
                                    {amenities.map((item, index) => (
                                        <BusinessPageAdditionalInfoBlockAmenitiesItem
                                            key={index}
                                        >
                                            <BusinessPageAdditionalInfoBlockAmenitiesItemIcon>
                                                {item.icon}
                                            </BusinessPageAdditionalInfoBlockAmenitiesItemIcon>
                                            <Text
                                                fontSize={TextFontSize.M}
                                                color={TextColor.Main}
                                            >
                                                {item.label}
                                            </Text>
                                        </BusinessPageAdditionalInfoBlockAmenitiesItem>
                                    ))}
                                </BusinessPageAdditionalInfoBlockAmenitiesItems>
                            </BusinessPageAdditionalInfoBlockAmenities>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <BusinessPageAdditionalInfoBlockShedule>
                                    <BusinessPageAdditionalInfoBlockSheduleRow>
                                        <Row>
                                            <Col xs={8}>
                                                <Text
                                                    fontSize={TextFontSize.M}
                                                    color={TextColor.Main}
                                                    fontWeight={TextWeight.BOLD}
                                                >
                                                    Mon - Fri
                                                </Text>
                                            </Col>
                                            <Col xs={8}>
                                                <Text
                                                    fontSize={TextFontSize.M}
                                                    color={TextColor.Main}
                                                >
                                                    09:00 — 20:00
                                                </Text>
                                            </Col>
                                        </Row>
                                    </BusinessPageAdditionalInfoBlockSheduleRow>
                                    <BusinessPageAdditionalInfoBlockSheduleRow>
                                        <Row>
                                            <Col xs={8}>
                                                <Text
                                                    fontSize={TextFontSize.M}
                                                    color={TextColor.Main}
                                                    fontWeight={TextWeight.BOLD}
                                                >
                                                    Sat
                                                </Text>
                                            </Col>
                                            <Col xs={8}>
                                                <Text
                                                    fontSize={TextFontSize.M}
                                                    color={TextColor.Main}
                                                >
                                                    Day off
                                                </Text>
                                            </Col>
                                        </Row>
                                    </BusinessPageAdditionalInfoBlockSheduleRow>
                                    <BusinessPageAdditionalInfoBlockSheduleRow>
                                        <Row>
                                            <Col xs={8}>
                                                <Text
                                                    fontSize={TextFontSize.M}
                                                    color={TextColor.Main}
                                                    fontWeight={TextWeight.BOLD}
                                                >
                                                    Sun
                                                </Text>
                                            </Col>
                                            <Col xs={8}>
                                                <Text
                                                    fontSize={TextFontSize.M}
                                                    color={TextColor.Main}
                                                >
                                                    Day off
                                                </Text>
                                            </Col>
                                        </Row>
                                    </BusinessPageAdditionalInfoBlockSheduleRow>
                                    <BusinessPageAdditionalInfoBlockSheduleRow>
                                        <Row>
                                            <Col xs={8}>
                                                <Text
                                                    fontSize={TextFontSize.M}
                                                    color={TextColor.Main}
                                                    fontWeight={TextWeight.BOLD}
                                                >
                                                    Lunch break
                                                </Text>
                                            </Col>
                                            <Col xs={8}>
                                                <Text
                                                    fontSize={TextFontSize.M}
                                                    color={TextColor.Main}
                                                >
                                                    Non stop
                                                </Text>
                                            </Col>
                                        </Row>
                                    </BusinessPageAdditionalInfoBlockSheduleRow>
                                </BusinessPageAdditionalInfoBlockShedule>
                            </Col>
                            <Col xs={12}>
                                <BusinessPageAdditionalInfoBlockAwards>
                                    {awards.map((item, index) => (
                                        <BusinessPageAdditionalInfoBlockAward
                                            key={index}
                                        >
                                            <Image
                                                src={item.img}
                                                height="40px"
                                            />
                                            <BusinessPageAdditionalInfoBlockAwardText>
                                                <Text
                                                    fontSize={TextFontSize.XS}
                                                    color={TextColor.Label}
                                                >
                                                    {item.label}
                                                </Text>
                                            </BusinessPageAdditionalInfoBlockAwardText>
                                        </BusinessPageAdditionalInfoBlockAward>
                                    ))}
                                </BusinessPageAdditionalInfoBlockAwards>
                            </Col>
                        </Row>
                    </BusinessPageAdditionalInfoBlock>
                    <Row>
                        <BusinessPageBrands>
                            <Row>
                                <BusinessPageBrandsTitle>
                                    <Text
                                        fontSize={TextFontSize.L}
                                        color={TextColor.Main}
                                    >
                                        We serve <b>Aston Martin</b> and
                                    </Text>
                                    <BusinessPageBrandsTitleButton
                                        onClick={handleBrandListClick}
                                    >
                                        <Text
                                            fontSize={TextFontSize.L}
                                            color="#ef3c22"
                                            fontWeight={TextWeight.BOLD}
                                            isLink
                                        >
                                            10 more brands
                                        </Text>
                                        <BusinessPageBrandsTitleButtonIcon
                                            isOpen={isBrandListOpen}
                                        >
                                            <CaretSvg
                                                width="10px"
                                                fill="#ef3c22"
                                            />
                                        </BusinessPageBrandsTitleButtonIcon>
                                    </BusinessPageBrandsTitleButton>
                                </BusinessPageBrandsTitle>
                                <BusinessPageBrandsList
                                    isOpen={isBrandListOpen}
                                >
                                    {brandsList.map((item, index) => (
                                        <BusinessPageBrandsListItem key={index}>
                                            <Image
                                                src={item.img}
                                                height="25px"
                                                width="25px"
                                            />
                                            <BusinessPageBrandsListItemText>
                                                <Text
                                                    fontSize={TextFontSize.M}
                                                    color={TextColor.Main}
                                                >
                                                    {item.label}
                                                </Text>
                                            </BusinessPageBrandsListItemText>
                                        </BusinessPageBrandsListItem>
                                    ))}
                                </BusinessPageBrandsList>
                            </Row>
                        </BusinessPageBrands>
                    </Row>
                    <Row>
                        <BusinessPageServicesBlock services={services} />
                    </Row>
                    <Row>
                        <BusinessPageDescription>
                            <BusinessPageDescriptionHeader>
                                <Text
                                    fontSize={TextFontSize.L}
                                    color={TextColor.Main}
                                    fontWeight={TextWeight.BOLD}
                                >
                                    Automated Oiler is the Best Automatic
                                    Controller
                                </Text>
                            </BusinessPageDescriptionHeader>
                            <Text
                                fontSize={TextFontSize.M}
                                color={TextColor.Main}
                            >
                                Automobiles Dandenong has been market leaders in
                                the motor industry for over 35 years with a 70%
                                repeat clientele base and outstanding customer
                                service. Our team have a wealth of experience in
                                the motor industry, so we know the science
                                behind a perfectly working car. Automobiles
                                Dandenong also has a state of the art service
                                department, a wheel and tyre centre and an
                                experienced finance department so we can cater
                                for all your motoring needs.
                            </Text>
                        </BusinessPageDescription>
                    </Row>
                    <Row>
                        <BusinessPageRating>
                            <BusinessPageTotalRating>
                                <Text
                                    fontSize={TextFontSize.L}
                                    color={TextColor.Main}
                                    fontWeight={TextWeight.BOLD}
                                >
                                    {rating.totalRating.name}
                                </Text>
                                <Rating value={rating.totalRating.rating} />
                            </BusinessPageTotalRating>
                        </BusinessPageRating>
                    </Row>
                </BusinessPageBusinessContent>
                <BusinessPageOrderContent></BusinessPageOrderContent>
            </BusinessPageContent>
        </BusinessPageWrapper>
    );
};

BusinessPage.displayName = 'BusinessPage';
