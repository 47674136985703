import styled, { css } from 'styled-components';
import { textFamilyMixin } from '@autobooking/components/src/component/text.component.styles';
import { Text, TextFamily } from '@autobooking/components';

export const HeaderWrapper = styled.div`
    top: 0;
    position: sticky;
    z-index: 10;
    background: #fff;
    padding: 15px 0;
    height: 80px;
    box-shadow: 0px 3px 15px 0 rgba(14, 15, 62, 0.15);
`;

export const HeaderContent = styled.div`
    display: flex;
    align-items: center;
`;

export const HeaderBurgerMenu = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 80px;
    height: calc(100vh - 80px);
    background-color: white;
    z-index: 5;
`;

export const HeaderBurgerMenuGrid = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const HeaderBurgerMenuGridItem = styled.div`
    display: inline-block;
    width: 100%;
    padding: 20px 15px;
    border-bottom: 1px solid #bdbdbd;
`;

export const SearchOptionsText = styled.div`
    margin-left: 12px;
    cursor: pointer;
`;

export const B2bText = styled.div`
    width: fit-content;
    padding: 16px 40px;
    border-radius: 10px;
    font-weight: bold;
    font-family: ${textFamilyMixin(TextFamily.BasisGrotesquePro)};
    ${({ theme }) => css`
        color: ${theme.palette.BrandBlue};
        border: 1px solid ${theme.palette.BrandBlue};
    `}

    &:hover {
        background-color: #0e0f3e;
        color: #fff;
        ${({ theme }) => css`
            color: ${theme.text.palette.reverse};
            background-color: ${theme.palette.BrandBlue};
        `}
    }
`;

export const HeaderSuccessRegistration = styled.div`
    width: 100%;
    max-width: 400px;
    margin: 30px auto;
    text-align: center;
`;

export const HeaderSuccessRegistrationDescription = styled(Text)`
    margin-top: 20px;
    text-align: justify;
`;
