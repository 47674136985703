import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
    Button,
    Text,
    TextColor,
    TextFontSize,
    TextWeight,
    GoogleSvg,
    Input,
    InputType,
    FacebookSvg,
} from '@autobooking/components';
import {
    AuthFormSignInTabWrapper,
    AuthFormSignInTabSvg,
    AuthFormSignInTabButton,
    AuthFormSignInTabLabel,
    AuthFormSignInTabEnterButtons,
    AuthFormSignInTabForm,
    AuthFormSignInTabFormItem,
    AuthFormSignInTabFormButton,
    AuthFormSignInTabText,
    AuthFormSignInTabTextItem,
} from './auth-form-sign-in-tab.component.styles';
import { APP_CONFIG_KEY, AppConfig } from '@source/config/app.config';
import { useYupValidationResolver } from '@source/hooks/yup-validation-resolver.hook';
import { GoogleLogin } from 'react-google-login';
import {
    AuthFormSignUpTabButton,
    AuthFormSignUpTabSvg,
} from '@source/components/auth-form/tabs/auth-form-sign-up-tab.component.styles';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

enum SignInForm {
    password = 'password',
    email = 'email',
}

export type ModalFormSignInData = {
    [SignInForm.password]?: string;
    [SignInForm.email]?: string;
};

export type AuthFormSignInTabProps = {
    onOpenForgotPassword: () => void;
    onOpenRegistration: () => void;
    onSignIn: (value: ModalFormSignInData) => void;
    onGoogleAuth: (accessToken: string) => void;
    onFacebookAuth?: (accessToken: string) => void;
};

export const AuthFormSignInTab: React.FC<AuthFormSignInTabProps> = ({
    onOpenForgotPassword,
    onOpenRegistration,
    onSignIn,
    onGoogleAuth,
    onFacebookAuth,
}) => {
    const [t] = AppCore.useTranslator(APP_CONFIG_KEY);
    const { googleId, facebookId } = AppCore.useConfig<AppConfig>(
        APP_CONFIG_KEY,
    );

    const signInSchema = yup.object().shape({
        [SignInForm.password]: yup
            .string()
            .label(t('auth: fields: password'))
            .required()
            .min(8)
            .max(30),
        [SignInForm.email]: yup
            .string()
            .label(t('auth: fields: email'))
            .required()
            .email(), // pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
    });

    const singInResolver = useYupValidationResolver(signInSchema);

    const defaultValuesForm = {
        [SignInForm.password]: '',
        [SignInForm.email]: '',
    };

    const {
        handleSubmit: handleSignIn,
        formState,
        control: controlSignIn,
    } = useForm({
        resolver: singInResolver,
        mode: 'all',
        shouldUnregister: false,
        defaultValues: defaultValuesForm,
    });

    const onGoogleLoginSuccess = async (response) => {
        if (response?.accessToken) {
            onGoogleAuth(response.accessToken);
        }
    };
    const onFacebookAuthSuccess = async (response) => {
        if (response?.accessToken) {
            onFacebookAuth(response.accessToken);
        }
    };

    return (
        <AuthFormSignInTabWrapper>
            <AuthFormSignInTabEnterButtons>
                <AuthFormSignInTabLabel>
                    <Text fontSize={TextFontSize.S}>
                        {t('auth: sign_in: one_click')}
                    </Text>
                </AuthFormSignInTabLabel>
                <AuthFormSignUpTabButton>
                    <FacebookLogin
                        appId={facebookId}
                        callback={onFacebookAuthSuccess}
                        render={({ onClick }) => (
                            <Button onClick={onClick} isOutline isFullWidth>
                                <AuthFormSignUpTabSvg>
                                    <FacebookSvg size="16px" />
                                </AuthFormSignUpTabSvg>
                                <Text
                                    fontWeight={TextWeight.BOLD}
                                    color={TextColor.Link}
                                    fontSize={TextFontSize.M}
                                >
                                    {t('auth: sign_in: facebook')}
                                </Text>
                            </Button>
                        )}
                    />
                </AuthFormSignUpTabButton>
                <AuthFormSignInTabButton>
                    <GoogleLogin
                        clientId={googleId}
                        render={({ onClick, disabled }) => (
                            <Button
                                isOutline
                                isFullWidth
                                onClick={onClick}
                                isDisabled={disabled}
                            >
                                <AuthFormSignInTabSvg>
                                    <GoogleSvg size="16px" />
                                </AuthFormSignInTabSvg>
                                <Text
                                    fontWeight={TextWeight.BOLD}
                                    color={TextColor.Link}
                                    fontSize={TextFontSize.M}
                                >
                                    {t('auth: sign_in: google')}
                                </Text>
                            </Button>
                        )}
                        onSuccess={onGoogleLoginSuccess}
                    />
                </AuthFormSignInTabButton>
            </AuthFormSignInTabEnterButtons>
            <AuthFormSignInTabLabel>
                <Text fontSize={TextFontSize.S}>
                    {t('auth: or_with_email')}
                </Text>
            </AuthFormSignInTabLabel>
            <AuthFormSignInTabForm onSubmit={handleSignIn(onSignIn)}>
                <AuthFormSignInTabFormItem>
                    <Controller
                        control={controlSignIn}
                        name={SignInForm.email}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name="email"
                                placeholder={t('auth: fields: email')}
                                isError={!!formState.errors[SignInForm.email]}
                                isVerified={
                                    (formState.isSubmitted ||
                                        formState.touchedFields?.[
                                            SignInForm.email
                                        ]) &&
                                    !formState.errors[SignInForm.email]
                                }
                                errorMessage={
                                    formState.errors[SignInForm.email]?.message
                                }
                            />
                        )}
                    />
                </AuthFormSignInTabFormItem>
                <AuthFormSignInTabFormItem>
                    <Controller
                        control={controlSignIn}
                        name={SignInForm.password}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                placeholder={t('auth: fields: password')}
                                onChange={onChange}
                                type={InputType.password}
                                onBlur={onBlur}
                                value={value}
                                name="password"
                                isError={
                                    !!formState.errors[SignInForm.password]
                                }
                                isVerified={
                                    (formState.isSubmitted ||
                                        formState.touchedFields?.[
                                            SignInForm.password
                                        ]) &&
                                    !formState.errors[SignInForm.password]
                                }
                                errorMessage={
                                    formState.errors[SignInForm.password]
                                        ?.message
                                }
                            />
                        )}
                    />
                </AuthFormSignInTabFormItem>
                <AuthFormSignInTabFormButton>
                    <Button isFullWidth isDisabled={!formState.isValid}>
                        {t('auth: sign_in: button')}
                    </Button>
                </AuthFormSignInTabFormButton>
            </AuthFormSignInTabForm>
            <AuthFormSignInTabText>
                <Text
                    isLink
                    fontWeight={TextWeight.BOLD}
                    color={TextColor.Link}
                    fontSize={TextFontSize.M}
                    onClick={onOpenForgotPassword}
                >
                    {t('auth: recovery: title')}
                </Text>
            </AuthFormSignInTabText>
            <AuthFormSignInTabText>
                <AuthFormSignInTabTextItem>
                    <Text fontSize={TextFontSize.M}>
                        {t('auth: sign_in: desc')}
                    </Text>
                </AuthFormSignInTabTextItem>
                <AuthFormSignInTabTextItem>
                    <Text
                        isLink
                        fontWeight={TextWeight.BOLD}
                        color={TextColor.Link}
                        fontSize={TextFontSize.M}
                        onClick={onOpenRegistration}
                    >
                        {t('auth: sign_up: button')}
                    </Text>
                </AuthFormSignInTabTextItem>
            </AuthFormSignInTabText>
        </AuthFormSignInTabWrapper>
    );
};
