import styled, { css } from 'styled-components';
import { ThemePalette } from '@autobooking/components';

export type BurgerWrapperProps = {
    isActive?: boolean;
};

export const BurgerWrapper = styled.div<BurgerWrapperProps>`
    position: relative;
    width: 34px;
    cursor: pointer;

    ${({ isActive }) =>
        isActive &&
        css`
            ${() => BurgerLine} {
                :nth-child(1) {
                    transform: rotate(45deg) translate(18%, 340%);
                }
                :nth-child(2) {
                    opacity: 0;
                }
                :nth-child(3) {
                    top: 0;
                    transform: rotate(-45deg) translate(18%, -290%);
                }
            }
        `}
`;

export type BurgerLineProps = {
    width?: string;
    color?: ThemePalette | string;
};

export const BurgerLine = styled.div<BurgerLineProps>`
    position: relative;
    height: 3px;
    width: ${({ width }) => width};
    background: ${({ theme, color }) => theme.palette[color] || color};
    transition: transform 0.2s ease-in, opacity 0.2s ease-in;
    margin-bottom: 8px;
    :last-child {
        margin-bottom: 0;
    }
`;

BurgerLine.defaultProps = {
    width: '100%',
    color: ThemePalette.PrimaryDark,
};
