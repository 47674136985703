import React from 'react';
import {
    BreadcrumbDelimiter,
    BreadcrumbItem,
    BreadcrumbWrapper,
} from './breadcrumb.component.styles';
import { CommonProps } from '@serviceplace/core';
import {
    Text,
    TextColor,
    TextFontSize,
    TextWeight,
} from '@autobooking/components';

export type BreadcrumbLinkType = {
    label: string;
    link?: string;
};

export type BreadcrumbProps = CommonProps & {
    delimiter?: string;
    links: BreadcrumbLinkType[];
    color?: TextColor | string;
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
    id,
    style,
    className,
    delimiter,
    links,
    color,
}) => (
    <BreadcrumbWrapper id={id} style={style} className={className}>
        {links &&
            links.map((item, index) => (
                <BreadcrumbItem key={index}>
                    {item.link ? (
                        <a href={item.link}>
                            <Text
                                fontSize={TextFontSize.S}
                                color={color}
                                fontWeight={TextWeight.NORMAL}
                            >
                                {item.label}
                            </Text>
                        </a>
                    ) : (
                        <Text
                            fontSize={TextFontSize.S}
                            color={color}
                            fontWeight={TextWeight.BOLD}
                        >
                            {item.label}
                        </Text>
                    )}
                    {links.length !== index + 1 && (
                        <BreadcrumbDelimiter>
                            <Text
                                fontSize={TextFontSize.S}
                                color={color}
                                fontWeight={TextWeight.BOLD}
                            >
                                {delimiter}
                            </Text>
                        </BreadcrumbDelimiter>
                    )}
                </BreadcrumbItem>
            ))}
    </BreadcrumbWrapper>
);

Breadcrumb.defaultProps = {
    delimiter: ' | ',
    color: TextColor.Reverse,
};
