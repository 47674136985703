import { Exception } from '@serviceplace/core';
import {
    CheckoutStep,
    CheckoutStore,
    CustomerData,
    CustomerField,
    VehicleField,
    VehicleData,
} from '@source/store/checkout.store';

export enum ModalStep {
    Customer = 'Customer',
    CarData = 'CarData',
}

export class CountryException extends Exception {
    constructor(reason: string) {
        super(`CheckoutService error. Reason ${reason}`);
    }
}

const SESSION_CHECKOUT_STORE = 'SESSION_CHECKOUT_STORE';

export interface CheckoutService {
    setStep(step: CheckoutStep): void;
    setModalStep(step: ModalStep): void;
    setCustomerData(customerData: CustomerData): void;
    setVehicleData(vehicleData: VehicleData): void;
    saveToSessionStore(): void;
    loadStoreFromSession(): void;
}

export class CheckoutServiceImpl implements CheckoutService {
    constructor(private _checkoutStore: CheckoutStore) {}

    setStep(step: CheckoutStep): void {
        this._checkoutStore.checkoutStep = step;
        this.saveToSessionStore();
    }

    setModalStep(step: ModalStep): void {
        this._checkoutStore.modalStep = step;
        this.saveToSessionStore();
    }

    setCustomerData(customerData: CustomerData): void {
        this._checkoutStore.customerData = customerData;
        this.saveToSessionStore();
    }

    setVehicleData(vehicleData: VehicleData): void {
        this._checkoutStore.vehicleData = vehicleData;
        this.saveToSessionStore();
    }

    saveToSessionStore() {
        sessionStorage.setItem(
            SESSION_CHECKOUT_STORE,
            JSON.stringify(this._checkoutStore),
        );
    }

    loadStoreFromSession() {
        const sessionData = sessionStorage.getItem(SESSION_CHECKOUT_STORE);
        if (sessionData) {
            const dateFields: string[] = [
                CustomerField.BirthDate,
                CustomerField.PassportDocumentDate,
                CustomerField.LicenseDocumentDate,
                CustomerField.IdCardDocumentDate,
                VehicleField.DateFrom,
            ];
            const parsedData: CheckoutStore = JSON.parse(
                sessionData,
                (key, value) => {
                    if (dateFields.includes(key)) {
                        return value ? new Date(value) : null;
                    }
                    return value;
                },
            );
            this._checkoutStore.checkoutStep = parsedData.checkoutStep;
            this._checkoutStore.modalStep = parsedData.modalStep;
            this._checkoutStore.customerData = { ...parsedData.customerData };
            this._checkoutStore.vehicleData = { ...parsedData.vehicleData };
        }
    }
}
