import { observable, makeObservable } from 'mobx';
import { SettingsModel } from '@source/data/model';

export interface SettingsStore {
    settings: SettingsModel;
}

export class SettingsStoreImpl implements SettingsStore {
    public readonly settings: SettingsModel = null;

    constructor() {
        makeObservable(this, {
            settings: observable,
        });
    }
}
