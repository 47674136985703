import styled, { css } from 'styled-components';
import { Modal } from '@autobooking/components';

type ModalWrapperProps = {
    width?: string;
};

export const ModalWrapper = styled(Modal)<ModalWrapperProps>`
    &.rs-modal {
        ${({ width }) =>
            css`
                width: ${width};
            `};
        min-height: 300px;
    }
    .rs-modal-content {
        padding: 0px;
        background-color: unset;
        box-shadow: none;
    }
`;

export const ModalBody = styled(Modal.Body)`
    overflow: auto;
    max-height: 100%;
`;
