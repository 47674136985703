import { observable, makeObservable } from 'mobx';
import { CityModel } from '@source/data/model';

export interface GeoPositionStore {
    geoPosition: CityModel;
}

export class GeoPositionStoreImpl implements GeoPositionStore {
    public readonly geoPosition: CityModel = null;

    constructor() {
        makeObservable(this, {
            geoPosition: observable,
        });
    }
}
