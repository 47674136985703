import styled from 'styled-components';

export const HeaderSearchOptionsFormWrapper = styled.div`
    width: 970px;
    border-radius: 24px;
    padding: 60px;
    background-color: #2e3757;
`;

export const HeaderSearchOptionsFormItem = styled.div`
    margin-bottom: 30px;
`;
