import * as React from 'react';
import {
    Dropdown,
    DropdownItem,
    Text,
    TextWeight,
    Box,
    Image,
} from '@autobooking/components';
import { CommonProps, useStateObserver } from '@serviceplace/core';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import { AppTypes } from '@source/types';
import { CountryStore } from '@source/store/country.store';
import { CountryService } from '@source/service/country.service';

export type HeaderCountryDropdownProps = CommonProps;

export const HeaderCountryDropdown: React.FC<HeaderCountryDropdownProps> = withDependencies(
    ({ $injected, ...props }) => {
        const [countryStore, countryService] = $injected.dependencies as [
            CountryStore,
            CountryService,
        ];

        const { countries, selectedCountry } = useStateObserver(
            () => ({
                countries: countryStore.countries,
                selectedCountry: countryStore.selectedCountry,
            }),
            [],
        );

        React.useEffect(() => {
            const fetchCountries = () => {
                if (!countries || countries?.length === 0) {
                    countryService.loadCountries();
                }
            };

            fetchCountries();
        }, []);

        if (!countries || countries?.length === 0 || !selectedCountry) {
            return <Box />;
        }

        return (
            <Dropdown
                {...props}
                title={
                    <Image
                        src={selectedCountry.flag}
                        width={'24px'}
                        height={'15px'}
                    />
                }
                activeKey={selectedCountry.countryCode}
                value={selectedCountry.countryCode}
            >
                {countries.map((item) => (
                    <DropdownItem
                        key={item.countryCode}
                        icon={
                            <Image
                                src={item.flag}
                                width={'24px'}
                                height={'15px'}
                            />
                        }
                        eventKey={item.countryCode}
                        onSelect={() => {
                            countryStore.selectedCountry = item;
                        }}
                    >
                        <Text
                            fontWeight={
                                selectedCountry.countryCode === item.countryCode
                                    ? TextWeight.BOLD
                                    : TextWeight.NORMAL
                            }
                        >
                            {item.name}
                        </Text>
                    </DropdownItem>
                ))}
            </Dropdown>
        );
    },
    { dependencies: [AppTypes.CountryStore, AppTypes.CountryService] },
);

HeaderCountryDropdown.displayName = 'HeaderCountryDropdown';
