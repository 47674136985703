import * as React from 'react';
import { CommonProps, useStateObserver } from '@serviceplace/core';
import {
    Box,
    Col,
    Container,
    FacebookSvg,
    Image,
    InstagramSvg,
    LinkedinSvg,
    Row,
    Select,
    Text,
    TextColor,
    TextFontSize,
    TextWeight,
    TwitterSvg,
    YoutubeSvg,
} from '@autobooking/components';
import {
    FooterApplications,
    FooterContentItem,
    FooterContentTitle,
    FooterCopyright,
    FooterSocialLinks,
    FooterSocialLinksItem,
    FooterWrapper,
} from './footer.component.styles';
import AppStoreImg from '@public/img/app-store.png';
import GooglePlayImg from '@public/img/google-play.png';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import { AppTypes } from '@source/types';
import { SettingsStore } from '@source/store/settings.store';
import { CountryStore } from '@source/store/country.store';
import { LocaleStore } from '@source/store/locale.store';
import { LinkType } from '@source/data/enum';
import { routes } from '@source/routes';
import { APP_CONFIG_KEY } from '@source/config/app.config';
import { MenuColumns } from '@source/data/enum';
import { SettingsMenuLink } from '@source/data/model';

export type FooterProps = CommonProps;

export const Footer: React.FC<FooterProps> = withDependencies(
    ({ $injected, ...props }) => {
        const [
            settingsStore,
            countryStore,
            localeStore,
        ] = $injected.dependencies as [
            SettingsStore,
            CountryStore,
            LocaleStore,
        ];
        const [t] = $injected.translators;
        const { currentLocale, setLocale } = AppCore.useI18n();
        const {
            countries,
            currentCountry,
            settings,
            locales,
        } = useStateObserver(
            () => ({
                countries: countryStore.countries,
                currentCountry: countryStore.selectedCountry,
                settings: settingsStore.settings,
                locales: localeStore.locales,
            }),
            [],
        );
        const preparedLocalesData = locales.map((item) => ({
            value: item.code,
            label: item.language,
        }));
        const preparedCountryData = countries.map((item) => ({
            value: item.countryCode,
            label: item.name,
        }));
        const changeCurrentCountry = (countryCode: string) => {
            countryStore.selectedCountry = countries.find(
                (item) => item.countryCode === countryCode,
            );
        };

        const firstColumnData = settings?.footerMenu.filter(
            (item) => item.column === MenuColumns.first,
        );

        const secondColumnData = settings?.footerMenu.filter(
            (item) => item.column === MenuColumns.second,
        );

        const renderAppsBlock = () =>
            (settings?.mobileApps?.google || settings?.mobileApps?.apple) && (
                <>
                    <FooterContentTitle>
                        <Text
                            color={TextColor.Reverse}
                            fontSize={TextFontSize.L}
                            fontWeight={TextWeight.BOLD}
                        >
                            {t('footer: mobile_applications: label')}
                        </Text>
                    </FooterContentTitle>
                    <FooterApplications>
                        {settings?.mobileApps?.google && (
                            <Box marginRight={'16px'}>
                                <a href={settings.mobileApps.google}>
                                    <Image
                                        src={GooglePlayImg}
                                        width="100px"
                                        height="30px"
                                    />
                                </a>
                            </Box>
                        )}
                        {settings?.mobileApps?.apple && (
                            <Box>
                                <a href={settings.mobileApps.apple}>
                                    <Image
                                        src={AppStoreImg}
                                        width="100px"
                                        height="30px"
                                    />
                                </a>
                            </Box>
                        )}
                    </FooterApplications>
                </>
            );

        const renderLocaleBlock = () => (
            <>
                <FooterContentItem>
                    <Text color={TextColor.Reverse} fontSize={TextFontSize.L}>
                        {t('footer: language: label')}
                    </Text>
                </FooterContentItem>
                <FooterContentItem>
                    <Select
                        data={preparedLocalesData}
                        value={currentLocale}
                        onChange={setLocale}
                    />
                </FooterContentItem>
                <FooterContentItem>
                    <Text color={TextColor.Reverse} fontSize={TextFontSize.L}>
                        {t('footer: country: label')}
                    </Text>
                </FooterContentItem>
                <FooterContentItem>
                    <Select
                        data={preparedCountryData}
                        value={currentCountry.countryCode}
                        onChange={changeCurrentCountry}
                    />
                </FooterContentItem>
            </>
        );

        const renderLinks = (links: SettingsMenuLink[]) =>
            links.map((item, index) => (
                <FooterContentItem key={index}>
                    <a
                        href={
                            item.type === LinkType.absolute
                                ? item.path
                                : routes.relativeLink.build({
                                      locale: currentLocale,
                                      country: currentCountry?.countryCode?.toLowerCase(),
                                      path: item.path,
                                  })
                        }
                    >
                        <Text
                            color={TextColor.Reverse}
                            fontSize={TextFontSize.M}
                            isLink
                        >
                            {item.title}
                        </Text>
                    </a>
                </FooterContentItem>
            ));

        return (
            <FooterWrapper {...props}>
                <Container>
                    <Row>
                        <Col xs={24} md={8}>
                            {!!firstColumnData?.length && (
                                <>
                                    <FooterContentTitle>
                                        <Text
                                            color={TextColor.Reverse}
                                            fontSize={TextFontSize.L}
                                            fontWeight={TextWeight.BOLD}
                                        >
                                            {t('footer: first_column: label')}
                                        </Text>
                                    </FooterContentTitle>
                                    {renderLinks(firstColumnData)}
                                </>
                            )}
                        </Col>
                        {!!secondColumnData?.length && (
                            <Col xs={24} md={8}>
                                <FooterContentTitle>
                                    <Text
                                        color={TextColor.Reverse}
                                        fontSize={TextFontSize.L}
                                        fontWeight={TextWeight.BOLD}
                                    >
                                        {t('footer: second_column: label')}
                                    </Text>
                                </FooterContentTitle>
                                {renderLinks(secondColumnData)}
                            </Col>
                        )}
                        {!secondColumnData?.length && (
                            <Col xs={24} md={8}>
                                {renderAppsBlock()}
                                {renderLocaleBlock()}
                            </Col>
                        )}
                        <Col xs={24} md={8}>
                            {!!secondColumnData?.length && renderLocaleBlock()}
                            {settings?.contacts?.hotLine && (
                                <>
                                    <FooterContentTitle>
                                        <Text
                                            color={TextColor.Reverse}
                                            fontSize={TextFontSize.L}
                                            fontWeight={TextWeight.BOLD}
                                        >
                                            {t('footer: support: label')}
                                        </Text>
                                    </FooterContentTitle>
                                    <Box marginBottom={'40px'}>
                                        <a
                                            href={`tel://${settings.contacts.hotLine}`}
                                        >
                                            <Text
                                                color={TextColor.Reverse}
                                                fontSize={TextFontSize.M}
                                                isLink
                                            >
                                                {settings.contacts.hotLine}
                                            </Text>
                                        </a>
                                    </Box>
                                </>
                            )}
                            {settings?.socialLinks && (
                                <>
                                    <FooterContentTitle>
                                        <Text
                                            color={TextColor.Reverse}
                                            fontSize={TextFontSize.L}
                                            fontWeight={TextWeight.BOLD}
                                        >
                                            {t('footer: social_links: label')}
                                        </Text>
                                    </FooterContentTitle>
                                    <FooterSocialLinks>
                                        {settings.socialLinks.facebook && (
                                            <FooterSocialLinksItem
                                                href={
                                                    settings.socialLinks
                                                        .facebook
                                                }
                                            >
                                                <FacebookSvg size={'30px'} />
                                            </FooterSocialLinksItem>
                                        )}
                                        {settings.socialLinks.instagram && (
                                            <FooterSocialLinksItem
                                                href={
                                                    settings.socialLinks
                                                        .instagram
                                                }
                                            >
                                                <InstagramSvg size={'30px'} />
                                            </FooterSocialLinksItem>
                                        )}
                                        {settings.socialLinks.youtube && (
                                            <FooterSocialLinksItem
                                                href={
                                                    settings.socialLinks.youtube
                                                }
                                            >
                                                <YoutubeSvg size={'30px'} />
                                            </FooterSocialLinksItem>
                                        )}
                                        {settings.socialLinks.twitter && (
                                            <FooterSocialLinksItem
                                                href={
                                                    settings.socialLinks.twitter
                                                }
                                            >
                                                <TwitterSvg size={'30px'} />
                                            </FooterSocialLinksItem>
                                        )}
                                        {settings.socialLinks.linkedin && (
                                            <FooterSocialLinksItem
                                                href={
                                                    settings.socialLinks
                                                        .linkedin
                                                }
                                            >
                                                <LinkedinSvg size={'30px'} />
                                            </FooterSocialLinksItem>
                                        )}
                                    </FooterSocialLinks>
                                </>
                            )}
                            {!!secondColumnData?.length && renderAppsBlock()}
                        </Col>
                    </Row>
                    <Row>
                        <FooterCopyright>
                            <Text color={TextColor.Reverse}>
                                © {new Date().getFullYear()}{' '}
                                {t('footer: copyright: label')}
                            </Text>
                            <Text color={TextColor.Tab}>
                                {t('footer: copyright: description')}
                            </Text>
                        </FooterCopyright>
                    </Row>
                </Container>
            </FooterWrapper>
        );
    },
    {
        translations: [APP_CONFIG_KEY],
        dependencies: [
            AppTypes.SettingsStore,
            AppTypes.CountryStore,
            AppTypes.LocaleStore,
        ],
    },
);

Footer.displayName = 'Footer';
