import { buildUrl, Path } from '@serviceplace/core';
import { removeEmptyObjectFields } from './utils/object.utils';

export type LocaleCountryParams = {
    locale: string;
    country: string;
};

export type RelativeLinkRouteParams = LocaleCountryParams & {
    path: string;
};
export const relativeLinkRoute = new Path<RelativeLinkRouteParams>(
    '/:locale-:country:path',
    ({ locale, country, path }) =>
        buildUrl(relativeLinkRoute.path, {
            pathParams: removeEmptyObjectFields({
                locale,
                country,
                path,
            }),
        }),
);

export type NotFoundRouteParams = LocaleCountryParams;

export const notFoundRoute = new Path<NotFoundRouteParams>(
    '/:locale-:country/404',
    ({ locale, country }) =>
        buildUrl(notFoundRoute.path, {
            pathParams: removeEmptyObjectFields({
                locale,
                country,
            }),
        }),
);

export type BusinessPageRouteParams = LocaleCountryParams;

export const businessPageRoute = new Path<BusinessPageRouteParams>(
    '/:locale-:country/business-page',
    ({ locale, country }) =>
        buildUrl(businessPageRoute.path, {
            pathParams: removeEmptyObjectFields({
                locale,
                country,
            }),
        }),
);

export const routes = {
    notFound: notFoundRoute,
    relativeLink: relativeLinkRoute,
    businessPage: businessPageRoute,
};
