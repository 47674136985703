export const APP_CORE_NAME = 'AppCore';

export enum AppTypes {
    /* Stores */
    LocaleStore = 'LocaleStore',
    CountryStore = 'CountryStore',
    SettingsStore = 'SettingsStore',
    UserInfoStore = 'UserInfoStore',
    GeoPositionStore = 'GeoPositionStore',
    CheckoutStore = 'CheckoutStore',

    /* Converters */
    LocaleDtoToModelConverter = 'LocaleDtoToModelConverter',
    CountryDtoToModelConverter = 'CountryDtoToModelConverter',
    SettingsDtoToModelConverter = 'SettingsDtoToModelConverter',
    GeoPositionDtoToModelConverter = 'GeoPositionDtoToModelConverter',
    UserDtoToModelConverter = 'UserDtoToModelConverter',
    SearchVehicleDtoToModelConverter = 'SearchVehicleDtoToModelConverter',

    /* APIs */
    AuthApi = 'AuthApi',
    LocaleApi = 'LocaleApi',
    CountryApi = 'CountryApi',
    SettingsApi = 'SettingsApi',
    GeoPositionApi = 'GeoPositionApi',
    SearchApi = 'SearchApi',

    /* Services */
    LocaleService = 'LocaleService',
    CountryService = 'CountryService',
    SettingsService = 'SettingsService',
    UserInfoService = 'UserInfoService',
    GeoPositionService = 'GeoPositionService',
    AuthService = 'AuthService',
    CheckoutService = 'CheckoutService',

    /* Validator */
}

export enum AppModules {
    Self = 'app',
}
