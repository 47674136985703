import { observable, makeObservable } from 'mobx';
import { CityModel, UserModel } from '@source/data/model';

export interface UserInfoStore {
    isAuthorized: boolean;
    userProfile: UserModel;
    geoPositionCity: CityModel;
    selectedCity: CityModel;
}

export class UserInfoStoreImpl implements UserInfoStore {
    public isAuthorized = false;
    public userProfile: UserModel = null;
    public geoPositionCity: CityModel = null;
    public selectedCity: CityModel = null;

    constructor() {
        makeObservable(this, {
            isAuthorized: observable,
            userProfile: observable,
            geoPositionCity: observable,
            selectedCity: observable,
        });
    }
}
