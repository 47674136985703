import styled, { css } from 'styled-components';
import {
    TextColor,
    TextFamily,
    TextFontSize,
    TextProps,
} from './text.component';
import { ComponentsTheme } from '@source/style/theme';

export const textFamilyMixin = (family: TextFamily): string =>
    ({
        [TextFamily.BasisGrotesquePro]: `'Basis Grotesque Pro', sans-serif !important`,
    }[family]);

export const textColorMixin = (
    color: TextColor | string,
    theme: ComponentsTheme,
): string => theme.text.palette[color] || color;

export const textFontSizeMixin = (fontSize: TextFontSize | string): string =>
    ({
        [TextFontSize.XXS]: '10px',
        [TextFontSize.XS]: '12px',
        [TextFontSize.S]: '14px',
        [TextFontSize.M]: '16px',
        [TextFontSize.L]: '18px',
        [TextFontSize.XL]: '30px',
        [TextFontSize.XXL]: '40px',
    }[fontSize] || fontSize);

export type TextWrapperProps = TextProps;

export const TextWrapper = styled.div<TextWrapperProps>`
    speak: none;
    font-style: normal;
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    ${({ fontSize, family, color, hoverColor, theme }) => css`
        font-size: ${textFontSizeMixin(fontSize) || fontSize};
        font-family: ${textFamilyMixin(family)};
        color: ${textColorMixin(color, theme)};
        &:hover {
            color: ${textColorMixin(hoverColor || color, theme)};
        }
    `};

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${({ isStrikeout }) =>
        isStrikeout &&
        css`
            text-decoration: line-through;
        `};

    ${({ isInline }) =>
        isInline &&
        css`
            display: inline-block;
        `};

    ${({ isLink, theme, color }) =>
        isLink &&
        css`
            color: ${textColorMixin(color, theme) || theme.text.palette.link};
            text-decoration: none;
        `};

    ${({ isLink, onClick }) =>
        (isLink || onClick) &&
        css`
            cursor: pointer;
        `};

    & > * {
        transition: inherit;
        transition-delay: inherit;
        transition-property: inherit;
        transition-duration: inherit;
    }
`;
