import styled from 'styled-components';

export const AuthFormSignInTabWrapper = styled.div`
    margin-top: 20px;
`;

export const AuthFormSignInTabSvg = styled.div`
    margin-right: 14px;
`;

export const AuthFormSignInTabButton = styled.div`
    margin-bottom: 15px;
`;

export const AuthFormSignInTabLabel = styled.div`
    margin-bottom: 8px;
`;

export const AuthFormSignInTabEnterButtons = styled.div`
    margin-bottom: 20px;
`;

export const AuthFormSignInTabForm = styled.form`
    margin-bottom: 25px;
`;

export const AuthFormSignInTabFormItem = styled.div`
    margin-bottom: 15px;
`;

export const AuthFormSignInTabFormButton = styled.div`
    margin-top: 20px;
`;

export const AuthFormSignInTabText = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
`;

export const AuthFormSignInTabTextItem = styled.div`
    margin-right: 5px;
`;
