import { UserModel } from '@source/data/model';
import { AppConfig } from '@source/config/app.config';
import { ServerResponseDto, UserDto, UserMetaDto } from '@source/data/dto';
import { InternationalizationStore } from '@serviceplace/core';
import { UserDtoToModelConverter } from '@source/converter/user-dto-to-model.converter';

export type AuthUserCredential = {
    email: string;
    password: string;
};

export type AuthRegistrationType = AuthUserCredential & {
    promoNotifyAgree?: boolean;
};

export interface AuthApi {
    registration({
        email,
        password,
        promoNotifyAgree,
    }: AuthRegistrationType): Promise<void>;
    login({ email, password }: AuthUserCredential): Promise<UserModel>;
    getUser(): Promise<UserModel>;
    logout(): Promise<void>;
    forgotPassword(email: string): Promise<UserModel>;
    facebookAuth(accessToken: string): Promise<UserModel>;
    googleAuth(accessToken: string): Promise<UserModel>;
}

export class AuthApiImpl implements AuthApi {
    constructor(
        private _appConfig: AppConfig,
        private _internationalizationStore: InternationalizationStore,
        private _userDtoToModelConverter: UserDtoToModelConverter,
    ) {}

    async registration({
        email,
        password,
        promoNotifyAgree,
    }: AuthRegistrationType): Promise<void> {
        const url = `${this._appConfig.host}/api/front/v2/auth/register`;

        const form = new FormData();
        form.append('user[email]', email);
        form.append('user[password]', password);
        if (promoNotifyAgree) {
            form.append(
                'user[promo_notify_agree]',
                promoNotifyAgree.toString(),
            );
        }

        await fetch(url, {
            method: 'POST',
            body: form,
            credentials: 'include',
            headers: {
                'Accept-Language': this._internationalizationStore
                    .currentLocale,
            },
        });
    }

    async login({ email, password }: AuthUserCredential): Promise<UserModel> {
        const url = `${this._appConfig.host}/api/front/v2/auth/login`;

        const form = new FormData();
        form.append('user[email]', email);
        form.append('user[password]', password);

        const res: Response = await fetch(url, {
            method: 'POST',
            body: form,
            credentials: 'include',
            headers: {
                'Accept-Language': this._internationalizationStore
                    .currentLocale,
            },
        });

        const resData: ServerResponseDto<
            UserDto,
            UserMetaDto
        > = await res.json();
        return this._userDtoToModelConverter.convert(resData);
    }

    async getUser(): Promise<UserModel> {
        const url = `${this._appConfig.host}/api/front/v2/profile`;

        const res: Response = await fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept-Language': this._internationalizationStore
                    .currentLocale,
            },
        });

        const resData: ServerResponseDto<
            UserDto,
            UserMetaDto
        > = await res.json();
        return this._userDtoToModelConverter.convert(resData);
    }

    async logout(): Promise<void> {
        const url = `${this._appConfig.host}/api/front/v2/auth/logout`;
        await fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept-Language': this._internationalizationStore
                    .currentLocale,
            },
        });
    }

    async forgotPassword(email: string): Promise<UserModel> {
        const url = `${this._appConfig.host}/api/front/v2/auth/password/forgot`;

        const form = new FormData();
        form.append('user[email]', email);

        const res: Response = await fetch(url, {
            method: 'POST',
            body: form,
            credentials: 'include',
            headers: {
                'Accept-Language': this._internationalizationStore
                    .currentLocale,
            },
        });

        const resData: ServerResponseDto<
            UserDto,
            UserMetaDto
        > = await res.json();
        return this._userDtoToModelConverter.convert(resData);
    }

    async facebookAuth(accessToken: string): Promise<UserModel> {
        const url = `${this._appConfig.host}/api/front/v2/auth/facebook`;

        const form = new FormData();
        form.append('access_token', accessToken);

        const res: Response = await fetch(url, {
            method: 'POST',
            body: form,
            credentials: 'include',
            headers: {
                'Accept-Language': this._internationalizationStore
                    .currentLocale,
            },
        });

        const resData: ServerResponseDto<
            UserDto,
            UserMetaDto
        > = await res.json();
        return this._userDtoToModelConverter.convert(resData);
    }

    async googleAuth(accessToken: string): Promise<UserModel> {
        const url = `${this._appConfig.host}/api/front/v2/auth/google`;

        const form = new FormData();
        form.append('access_token', accessToken);

        const res: Response = await fetch(url, {
            method: 'POST',
            body: form,
            credentials: 'include',
            headers: {
                'Accept-Language': this._internationalizationStore
                    .currentLocale,
            },
        });

        const resData: ServerResponseDto<
            UserDto,
            UserMetaDto
        > = await res.json();
        return this._userDtoToModelConverter.convert(resData);
    }
}
