import * as React from 'react';
import { Header } from '@source/components/header/header.component';
import { Footer } from '@source/components/footer.component';
import { ChildrenProps } from '@serviceplace/core';
import {
    PageLayoutWrapper,
    PageLayoutContent,
} from './page-layout.component.styles';

export type PageLayoutProps = ChildrenProps & {
    isFooterExist?: boolean;
};

export const HeaderHeightContext = React.createContext(0);

export const PageLayout: React.FC<PageLayoutProps> = ({
    isFooterExist,
    children,
}) => {
    const headerRef = React.useRef<HTMLDivElement>(null);
    const [headerHeight, setHeaderHeight] = React.useState<number>(0);

    const setHeader = () => {
        if (headerRef?.current?.offsetHeight) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
    };

    React.useEffect(() => {
        setHeader();
        window.addEventListener('resize', setHeader);

        return () => {
            window.removeEventListener('resize', setHeader);
        };
    }, []);

    return (
        <PageLayoutWrapper>
            <Header ref={headerRef} />
            <HeaderHeightContext.Provider value={headerHeight}>
                <PageLayoutContent>{children}</PageLayoutContent>
            </HeaderHeightContext.Provider>
            {isFooterExist && <Footer />}
        </PageLayoutWrapper>
    );
};

PageLayout.displayName = 'PageLayout';

PageLayout.defaultProps = {
    isFooterExist: true,
};
