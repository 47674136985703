import { Converter } from '@serviceplace/core';
import { ServerResponseDto, UserDto, UserMetaDto } from '@source/data/dto';
import { UserModel } from '@source/data/model';

export type UserDtoToModelConverter = Converter<
    ServerResponseDto<UserDto, UserMetaDto>,
    UserModel
>;

export class UserDtoToModelConverterImpl
    extends Converter<ServerResponseDto<UserDto, UserMetaDto>, UserModel>
    implements UserDtoToModelConverter {
    convert(source: ServerResponseDto<UserDto, UserMetaDto>): UserModel {
        return {
            id: source.data.id.toString(),
            email: source.data.email,
            role: source.data.role,
            firstName: source.data.first_name,
            lastName: source.data.last_name,
            avatarImageUrl: source.data.avatar_image_url,
            countryCode: source.data.country_code,
            cars:
                source.data.cars?.map((car) => ({
                    fuelType: car.fuel_type,
                    fullName: car.full_name,
                    milage: car.milage,
                    modification: car.modification,
                    note: car.note,
                    photoUrl: car.photo_url,
                    registrationNumber: car.registration_number,
                    vinNumber: car.vin_number,
                    year: car.year,
                })) || [],
            phone: source.data.phone,
            hasPaidInsurance: source.data.has_paid_insurance,
            documents: [...source.data.documents],
            helpLinks: source.meta
                ? source.meta.help_links.filter((item) => !!item.title)
                : null,
        } as UserModel;
    }
}
