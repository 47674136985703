import {
    Core,
    CoreConfig,
    createCoreInstance,
    setEventBusDevTool,
} from '@serviceplace/core';
import { APP_CORE_NAME, AppModules, AppTypes } from '@source/types';
import { AppEvents } from '@source/events';
import { i18nLoader } from '@source/loader/i18n.loader';
import { configLoader } from '@source/loader/config.loader';
import { APP_CONFIG_KEY, AppConfig } from '@source/config/app.config';
import { CountryApi, CountryApiImpl } from '@source/api/country.api';
import {
    CountryDtoToModelConverter,
    CountryDtoToModelConverterImpl,
} from '@source/converter/country-dto-to-model.converter';
import { LocaleApi, LocaleApiImpl } from '@source/api/locale.api';
import {
    LocaleDtoToModelConverter,
    LocaleDtoToModelConverterImpl,
} from '@source/converter/locale-dto-to-model.converter';
import {
    CountryService,
    CountryServiceImpl,
} from '@source/service/country.service';
import { CountryStore, CountryStoreImpl } from '@source/store/country.store';
import {
    LocaleService,
    LocaleServiceImpl,
} from '@source/service/locale.service';
import { LocaleStore, LocaleStoreImpl } from '@source/store/locale.store';
import { CountryModel } from '@source/data/model';
import { routes } from '@source/routes';
import { SettingsApi, SettingsApiImpl } from '@source/api/settings.api';
import {
    SettingsDtoToModelConverter,
    SettingsDtoToModelConverterImpl,
} from '@source/converter/settings-dto-to-model.converter';
import { SettingsServiceImpl } from '@source/service/settings.service';
import { SettingsStore, SettingsStoreImpl } from '@source/store/settings.store';
import {
    GeoPositionApiImpl,
    GeoPositionApi,
} from '@source/api/geo-position.api';
import { GeoPositionDtoToModelConverterImpl } from '@source/converter/geo-position-dto-to-city-model.converter';
import { GeoPositionServiceImpl } from '@source/service/geo-position.service';
import {
    GeoPositionStore,
    GeoPositionStoreImpl,
} from '@source/store/geo-position.store';
import { UserInfoServiceImpl } from '@source/service/user-info.service';
import {
    UserInfoStore,
    UserInfoStoreImpl,
} from '@source/store/user-info.store';
import { AuthApi, AuthApiImpl } from '@source/api/auth.api';
import { AuthServiceImpl } from '@source/service/auth.service';
import {
    UserDtoToModelConverter,
    UserDtoToModelConverterImpl,
} from '@source/converter/user-dto-to-model.converter';
import { CheckoutStore, CheckoutStoreImpl } from '@source/store/checkout.store';
import { CheckoutServiceImpl } from '@source/service/checkout.service';
import { SearchApi, SearchApiImpl } from '@source/api/search.api';
import { SearchVehicleDtoToModelConverterImpl } from '@source/converter/search-vehicle-dto-to-model.converter';

export const setupAppCore = async (): Promise<Core> => {
    const config: CoreConfig = {
        name: APP_CORE_NAME,
        translateUnitLoaders: [
            {
                context: AppModules.Self,
                loader: i18nLoader,
            },
        ],
        configLoaders: [
            {
                context: AppModules.Self,
                loader: configLoader,
            },
        ],
    };

    const appCore = await createCoreInstance(config);

    // Register events
    Object.values(AppEvents).forEach((eventName) =>
        appCore.eventBus.registerEvent(eventName),
    );

    const resolveAppConfig = async () => {
        const config = await appCore.config.service.loadConfig<AppConfig>({
            key: APP_CONFIG_KEY,
            context: AppModules.Self,
        });
        return config.data;
    };

    // Apis
    appCore.bottle.factory(AppTypes.AuthApi, async (container) => {
        return new AuthApiImpl(
            await resolveAppConfig(),
            await appCore.internationalization.store,
            (await container[
                AppTypes.UserDtoToModelConverter
            ]) as UserDtoToModelConverter,
        );
    });
    appCore.bottle.factory(AppTypes.CountryApi, async (container) => {
        return new CountryApiImpl(
            await resolveAppConfig(),
            await appCore.internationalization.store,
            (await container[
                AppTypes.CountryDtoToModelConverter
            ]) as CountryDtoToModelConverter,
        );
    });
    appCore.bottle.factory(AppTypes.LocaleApi, async (container) => {
        return new LocaleApiImpl(
            await resolveAppConfig(),
            await appCore.internationalization.store,
            (await container[
                AppTypes.LocaleDtoToModelConverter
            ]) as LocaleDtoToModelConverter,
        );
    });
    appCore.bottle.factory(AppTypes.SettingsApi, async (container) => {
        return new SettingsApiImpl(
            await resolveAppConfig(),
            await appCore.internationalization.store,
            (await container[
                AppTypes.SettingsDtoToModelConverter
            ]) as SettingsDtoToModelConverter,
        );
    });
    appCore.bottle.factory(AppTypes.GeoPositionApi, async (container) => {
        return new GeoPositionApiImpl(
            await resolveAppConfig(),
            await appCore.internationalization.store,
            (await container[
                AppTypes.GeoPositionDtoToModelConverter
            ]) as GeoPositionDtoToModelConverterImpl,
        );
    });
    appCore.bottle.factory(AppTypes.SearchApi, async (container) => {
        return new SearchApiImpl(
            await resolveAppConfig(),
            await appCore.internationalization.store,
            (await container[
                AppTypes.SearchVehicleDtoToModelConverter
            ]) as SearchVehicleDtoToModelConverterImpl,
        );
    });

    // Converters
    appCore.bottle.factory(
        AppTypes.CountryDtoToModelConverter,
        async () => new CountryDtoToModelConverterImpl(),
    );
    appCore.bottle.factory(
        AppTypes.LocaleDtoToModelConverter,
        async () => new LocaleDtoToModelConverterImpl(),
    );
    appCore.bottle.factory(
        AppTypes.SettingsDtoToModelConverter,
        async () => new SettingsDtoToModelConverterImpl(),
    );
    appCore.bottle.factory(
        AppTypes.GeoPositionDtoToModelConverter,
        async () => new GeoPositionDtoToModelConverterImpl(),
    );
    appCore.bottle.factory(
        AppTypes.UserDtoToModelConverter,
        async () => new UserDtoToModelConverterImpl(),
    );
    appCore.bottle.factory(
        AppTypes.SearchVehicleDtoToModelConverter,
        async () => new SearchVehicleDtoToModelConverterImpl(),
    );

    // Services
    appCore.bottle.factory(
        AppTypes.AuthService,
        async (container) =>
            new AuthServiceImpl(
                (await container[AppTypes.UserInfoStore]) as UserInfoStore,
                (await container[AppTypes.AuthApi]) as AuthApi,
                (await container[AppTypes.CheckoutStore]) as CheckoutStore,
                (await container[AppTypes.SearchApi]) as SearchApi,
            ),
    );
    appCore.bottle.factory(
        AppTypes.CountryService,
        async (container) =>
            new CountryServiceImpl(
                (await container[AppTypes.CountryStore]) as CountryStore,
                (await container[AppTypes.CountryApi]) as CountryApi,
            ),
    );
    appCore.bottle.factory(
        AppTypes.LocaleService,
        async (container) =>
            new LocaleServiceImpl(
                (await container[AppTypes.LocaleStore]) as LocaleStore,
                (await container[AppTypes.LocaleApi]) as LocaleApi,
            ),
    );
    appCore.bottle.factory(
        AppTypes.SettingsService,
        async (container) =>
            new SettingsServiceImpl(
                (await container[AppTypes.SettingsStore]) as SettingsStore,
                (await container[AppTypes.SettingsApi]) as SettingsApi,
            ),
    );
    appCore.bottle.factory(
        AppTypes.GeoPositionService,
        async (container) =>
            new GeoPositionServiceImpl(
                (await container[
                    AppTypes.GeoPositionStore
                ]) as GeoPositionStore,
                (await container[AppTypes.GeoPositionApi]) as GeoPositionApi,
            ),
    );
    appCore.bottle.factory(
        AppTypes.UserInfoService,
        async (container) =>
            new UserInfoServiceImpl(
                (await container[AppTypes.UserInfoStore]) as UserInfoStore,
            ),
    );
    appCore.bottle.factory(
        AppTypes.CheckoutService,
        async (container) =>
            new CheckoutServiceImpl(
                (await container[AppTypes.CheckoutStore]) as CheckoutStore,
            ),
    );

    // Stores
    appCore.bottle.factory(
        AppTypes.CountryStore,
        async () => new CountryStoreImpl(),
    );
    appCore.bottle.factory(
        AppTypes.LocaleStore,
        async () => new LocaleStoreImpl(),
    );
    appCore.bottle.factory(
        AppTypes.SettingsStore,
        async () => new SettingsStoreImpl(),
    );
    appCore.bottle.factory(
        AppTypes.UserInfoStore,
        async () => new UserInfoStoreImpl(),
    );
    appCore.bottle.factory(
        AppTypes.GeoPositionStore,
        async () => new GeoPositionStoreImpl(),
    );
    appCore.bottle.factory(
        AppTypes.CheckoutStore,
        async () => new CheckoutStoreImpl(),
    );

    /*
     * Use this place to add dependencies to container
     * */

    // Start setup locales and country TODO: refactor
    const i18nStore = appCore.internationalization.store;
    const localeService = (await appCore.container[
        AppTypes.LocaleService
    ]) as LocaleService;

    const locales = await localeService.loadLocales();

    const localeCodes = locales.map((it) => it.code);
    const preparedLocale = prepareLocale(localeCodes);

    i18nStore.locales = localeCodes;
    i18nStore.currentLocale = preparedLocale;
    i18nStore.defaultLocale = preparedLocale;

    const countryService = (await appCore.container[
        AppTypes.CountryService
    ]) as CountryService;
    const countryStore = (await appCore.container[
        AppTypes.CountryStore
    ]) as CountryStore;
    const countries = await countryService.loadCountries();
    countryStore.selectedCountry = prepareCountry(countries);
    // End setup locales and country

    // TODO add env check
    setEventBusDevTool({
        onEvent: (event) =>
            console.log(
                `${event.eventBusName}: ${event.eventName} [${
                    typeof event.data === 'object'
                        ? JSON.stringify(event.data)
                        : event.data
                }]`,
            ),
    });

    return appCore;
};

function prepareCountry(availableCountries: CountryModel[]): CountryModel {
    const getCountry = (country: string) =>
        availableCountries.find(
            (it) => it.countryCode === country.toLocaleUpperCase(),
        );
    const countryFromUrl = document.location.pathname
        .split('/')?.[1]
        ?.split('-')?.[1];
    const currentCountry = getCountry(countryFromUrl);
    if (currentCountry) {
        return currentCountry;
    } else {
        document.location.href = routes.notFound.build({
            locale: 'ua',
            country: 'ua',
        });
    }
}

function prepareLocale(availableLocales: string[]) {
    const checkLocal = (lang: string) =>
        !!availableLocales.find((it) => it === lang);
    const langFromUrl = document.location.pathname
        .split('/')?.[1]
        ?.split('-')?.[0];

    if (checkLocal(langFromUrl)) {
        return langFromUrl;
    } else {
        document.location.href = routes.notFound.build({
            locale: 'ua',
            country: 'ua',
        });
    }
}
