import * as React from 'react';
import { CommonProps } from '@serviceplace/core';
import {
    NotFoundPageWrapper,
    NotFoundPageContent,
} from './not-found-page.component.styles';
import { Container, Text, TextFontSize } from '@autobooking/components';

export type NotFoundPageProps = CommonProps;

export const NotFoundPage: React.FC<NotFoundPageProps> = ({
    ...commonProps
}) => {

    return (
        <NotFoundPageWrapper {...commonProps}>
            <Container>
                <NotFoundPageContent>
                    <Text fontSize={TextFontSize.XL}>Not Found</Text>
                </NotFoundPageContent>
            </Container>
        </NotFoundPageWrapper>
    );
};

NotFoundPage.displayName = 'NotFoundPage';
