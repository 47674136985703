import React from 'react';
import { CommonProps, ChildrenProps } from '@serviceplace/core';
import { ModalWrapper, ModalBody } from './modal.component.styles';

export type ModalProps = CommonProps &
    ChildrenProps & {
        show: boolean;
        onHide?: () => void;
        width?: string;
    };

export const Modal: React.FC<ModalProps> = ({
    show,
    onHide,
    width,
    children,
    ...commonProps
}) => {
    return (
        <ModalWrapper
            show={show}
            onHide={onHide}
            width={width}
            {...commonProps}
        >
            <ModalBody>{children}</ModalBody>
        </ModalWrapper>
    );
};
