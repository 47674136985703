import * as React from 'react';
import { TextWrapper } from './text.component.styles';
import { CommonProps } from '@serviceplace/core';

export enum TextFontSize {
    XXL = 'xxl',
    XL = 'xl',
    L = 'l',
    M = 'm',
    S = 's',
    XS = 'xs',
    XXS = 'xxs',
}

export enum TextColor {
    Main = 'main',
    Label = 'label',
    Reverse = 'reverse',
    Disable = 'disable',
    Link = 'link',
    Error = 'error',
    Tab = 'tab',
}

export enum TextWeight {
    NORMAL = 'normal',
    BOLD = 'bold',
}

export enum TextFamily {
    BasisGrotesquePro = 'Basis Grotesque Pro',
}

export type TextProps = CommonProps & {
    color?: TextColor | string;
    hoverColor?: TextColor | string;
    family?: TextFamily;
    fontSize?: TextFontSize | string;
    fontWeight?: TextWeight;
    isStrikeout?: boolean;
    isInline?: boolean;
    isLink?: boolean;
    as?: keyof JSX.IntrinsicElements;
    onClick?: () => void;
};

export const Text: React.FC<TextProps> = (props) => (
    <TextWrapper {...props}>{props.children}</TextWrapper>
);

Text.defaultProps = {
    color: TextColor.Main,
    family: TextFamily.BasisGrotesquePro,
    fontSize: TextFontSize.S,
};
