import styled from 'styled-components';

export const BreadcrumbWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const BreadcrumbItem = styled.div`
    display: flex;
    align-items: center;
`;

export const BreadcrumbDelimiter = styled.div`
    padding: 0 5px;
`;
