import { Exception } from '@serviceplace/core';
import { SettingsModel } from '@source/data/model';
import { SettingsStore } from '@source/store/settings.store';
import { SettingsApi } from '@source/api/settings.api';

export class SettingsException extends Exception {
    constructor(reason: string) {
        super(`SettingsService error. Reason ${reason}`);
    }
}

export interface SettingsService {
    loadSettings(country: string): Promise<SettingsModel>;
}

export class SettingsServiceImpl implements SettingsService {
    constructor(
        private _settingsStore: SettingsStore,
        private _settingsApi: SettingsApi,
    ) {}

    async loadSettings(country: string): Promise<SettingsModel> {
        try {
            const settings = await this._settingsApi.loadSettings(country);
            this._settingsStore.settings = settings;
            return settings;
        } catch (err) {
            throw new SettingsException(err);
        }
    }
}
