import * as React from 'react';
import { CommonProps } from '@serviceplace/core';
import {
    Text,
    TextFontSize,
    TextWeight,
    TextColor,
} from '@autobooking/components';
import {
    BusinessPageServicesBlockWrapper,
    BusinessPageServicesBlockLabel,
    BusinessPageServicesBlockGroupWrapper,
    BusinessPageServicesBlockGroupHeader,
    BusinessPageServicesBlockGroupBody,
    BusinessPageServicesBlockGroupName,
    BusinessPageServicesBlockGroupButton,
    BusinessPageServicesBlockGroupServiceWrapper,
    BusinessPageServicesBlockGroupServiceLabel,
    BusinessPageServicesBlockGroupServiceButton,
    BusinessPageServicesBlockGroupServiceLabelName,
} from './business-page-services-block.component.styles';

export type BusinessPageServicesBlockProps = CommonProps & {
    services: {
        name: string;
        servicesCount: number;
        servicesList: {
            name: string;
            price: string;
        }[];
    }[];
};

export const BusinessPageServicesBlock: React.FC<BusinessPageServicesBlockProps> = ({
    services,
}) => {
    return (
        <BusinessPageServicesBlockWrapper>
            <BusinessPageServicesBlockLabel>
                <Text
                    fontSize={TextFontSize.L}
                    color={TextColor.Main}
                    fontWeight={TextWeight.BOLD}
                >
                    List of services
                </Text>
            </BusinessPageServicesBlockLabel>
            {services.map((group) => (
                <BusinessPageServicesBlockGroup
                    group={group}
                    key={group.name}
                />
            ))}
        </BusinessPageServicesBlockWrapper>
    );
};

export type BusinessPageServicesBlockGroupProps = CommonProps & {
    group: {
        name: string;
        servicesCount: number;
        servicesList: {
            name: string;
            price: string;
        }[];
    };
};

export const BusinessPageServicesBlockGroup: React.FC<BusinessPageServicesBlockGroupProps> = ({
    group,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClick = () => setIsOpen(!isOpen);

    return (
        <BusinessPageServicesBlockGroupWrapper>
            <BusinessPageServicesBlockGroupHeader>
                <BusinessPageServicesBlockGroupName>
                    <Text fontSize={TextFontSize.L} color={TextColor.Main}>
                        {group.name}
                    </Text>
                </BusinessPageServicesBlockGroupName>
                <BusinessPageServicesBlockGroupButton
                    onClick={handleClick}
                    isOpen={isOpen}
                >
                    {group.servicesCount} Services
                </BusinessPageServicesBlockGroupButton>
            </BusinessPageServicesBlockGroupHeader>

            {isOpen && (
                <BusinessPageServicesBlockGroupBody>
                    {group.servicesList.map((service) => (
                        <BusinessPageServicesBlockGroupService
                            service={service}
                            key={service.name}
                        />
                    ))}
                </BusinessPageServicesBlockGroupBody>
            )}
        </BusinessPageServicesBlockGroupWrapper>
    );
};

export type BusinessPageServicesBlockGroupServiceProps = CommonProps & {
    service: {
        name: string;
        price: string;
    };
};

export const BusinessPageServicesBlockGroupService: React.FC<BusinessPageServicesBlockGroupServiceProps> = ({
    service,
}) => {
    const [isHovered, setIsHovered] = React.useState(false);

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    return (
        <BusinessPageServicesBlockGroupServiceWrapper
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <BusinessPageServicesBlockGroupServiceLabel>
                <BusinessPageServicesBlockGroupServiceLabelName>
                    <Text fontSize={TextFontSize.L} color={TextColor.Main}>
                        {service.name}
                    </Text>
                </BusinessPageServicesBlockGroupServiceLabelName>
                <Text
                    fontSize={TextFontSize.L}
                    color={TextColor.Main}
                    fontWeight={TextWeight.BOLD}
                >
                    {service.price}
                </Text>
            </BusinessPageServicesBlockGroupServiceLabel>
            <BusinessPageServicesBlockGroupServiceButton isHovered={isHovered}>
                Заказать
            </BusinessPageServicesBlockGroupServiceButton>
        </BusinessPageServicesBlockGroupServiceWrapper>
    );
};
