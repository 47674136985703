import * as React from 'react';
import {
    AddedHocProps,
    CommonProps,
    useStateObserver,
} from '@serviceplace/core';
import {
    Box,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownTrigger,
    LogoSvg,
    Text,
    TextFontSize,
    TextWeight,
    TextColor,
    SearchSvg,
} from '@autobooking/components';
import { HeaderCountryDropdown } from './header-country-dropdown.component';
import {
    HeaderBurgerMenu,
    HeaderBurgerMenuGrid,
    HeaderBurgerMenuGridItem,
    HeaderContent,
    HeaderWrapper,
    SearchOptionsText,
    B2bText,
    HeaderSuccessRegistration,
    HeaderSuccessRegistrationDescription,
} from './header.component.styles';
import { Link } from '@source/components/link.component';
import { Burger } from '@source/components/burger.component';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import { AppTypes } from '@source/types';
import { LocaleStore } from '@source/store/locale.store';
import { SettingsStore } from '@source/store/settings.store';
import { APP_CONFIG_KEY } from '@source/config/app.config';
import { routes } from '@source/routes';
import { CountryStore } from '@source/store/country.store';
import { HeaderSearchOptionsForm } from './header-search-options-form.component';
import { Modal } from '@source/components/modal.component';
import {
    AuthForm,
    AuthFormStatus,
    AuthFormTabKey,
} from '@source/components/auth-form/auth-form.component';
import { UserInfoStore } from '@source/store/user-info.store';
import { HeaderAuth } from '@source/components/header/header-auth.component';

export type HeaderProps = CommonProps;

export const Header = withDependencies<
    HeaderProps,
    AddedHocProps,
    HTMLDivElement
>(
    ({ $injected, ...props }, ref) => {
        const { currentLocale, setLocale } = AppCore.useI18n();
        const [isBurgerActive, setIsBurgerActive] = React.useState(false);
        const [defaultTab, setDefaultTab] = React.useState<AuthFormTabKey>(
            null,
        );
        const [isModalOpen, setIsModalOpen] = React.useState(false);
        const [
            isSuccessRegistrationModalOpen,
            setIsSuccessRegistrationModalOpen,
        ] = React.useState(false);
        const [registrationEmail, setRegistrationEmail] = React.useState('');

        const [t] = $injected.translators;
        const [
            localeStore,
            settingsStore,
            countryStore,
            userInfoStore,
        ] = $injected.dependencies as [
            LocaleStore,
            SettingsStore,
            CountryStore,
            UserInfoStore,
        ];
        const {
            isAuthorized,
            locales,
            settings,
            currentCountry,
        } = useStateObserver(
            () => ({
                isAuthorized: userInfoStore?.isAuthorized,
                locales: localeStore.locales,
                settings: settingsStore.settings,
                currentCountry: countryStore?.selectedCountry?.countryCode?.toLowerCase(),
            }),
            [],
        );

        const [
            isShowSearchOptionsFormModal,
            setIsShowSearchOptionsFormModal,
        ] = React.useState(false);

        const closeSearchOptionsFormModal = () =>
            setIsShowSearchOptionsFormModal(false);
        const openSearchOptionsFormModal = () =>
            setIsShowSearchOptionsFormModal(true);

        const openAuthModal = (defaultTab: AuthFormTabKey) => {
            setDefaultTab(defaultTab);
            setIsModalOpen(true);
        };

        React.useEffect(() => {
            if (!isBurgerActive) {
                return;
            }
            const savedHeightBodyStyle = document.body.style.height;
            const savedOverflowBodyStyle = document.body.style.overflow;

            document.body.style.height = '100vh';
            document.body.style.overflow = 'hidden';

            return () => {
                document.body.style.height = savedHeightBodyStyle;
                document.body.style.overflow = savedOverflowBodyStyle;
            };
        }, [isBurgerActive]);

        const renderPhone = () => {
            if (settings?.contacts?.showPhoneInHeader) {
                return (
                    <Box>
                        <Text
                            fontSize={TextFontSize.XS}
                            color={TextColor.Label}
                        >
                            support 24/7
                        </Text>
                        <a href={`tel:${settings.contacts.hotLine}`}>
                            <Text
                                fontSize={TextFontSize.M}
                                fontWeight={TextWeight.BOLD}
                            >
                                {settings.contacts.hotLine}
                            </Text>
                        </a>
                    </Box>
                );
            }
        };

        const renderB2bLink = () => (
            <a
                href={routes.relativeLink.build({
                    locale: currentLocale,
                    country: currentCountry,
                    path: '/b2b',
                })}
            >
                <B2bText>{t('header: b2b: link')}</B2bText>
            </a>
        );

        const renderSignBlock = () =>
            isAuthorized ? (
                <HeaderAuth />
            ) : (
                <Box display="flex">
                    <Text
                        isLink
                        fontSize={TextFontSize.M}
                        fontWeight={TextWeight.BOLD}
                        onClick={() => openAuthModal(AuthFormTabKey.SignIn)}
                    >
                        {t('header: login: link')}
                    </Text>
                    <span>\</span>
                    <Text
                        isLink
                        fontSize={TextFontSize.M}
                        fontWeight={TextWeight.BOLD}
                        onClick={() => openAuthModal(AuthFormTabKey.SignUp)}
                    >
                        {t('header: register: link')}
                    </Text>
                </Box>
            );

        const renderSearchOptions = () => (
            <Box display="flex" alignItems="center">
                <SearchSvg size="20px" />
                <SearchOptionsText onClick={openSearchOptionsFormModal}>
                    <Text
                        fontSize={TextFontSize.M}
                        fontWeight={TextWeight.BOLD}
                    >
                        Search options
                    </Text>
                </SearchOptionsText>
            </Box>
        );

        const handleAuthSuccess = (status?: AuthFormStatus, email?: string) => {
            setIsModalOpen(false);
            if (status === AuthFormStatus.Registration) {
                setRegistrationEmail(email);
                setIsSuccessRegistrationModalOpen(true);
            }
        };

        return (
            <HeaderWrapper {...props} ref={ref}>
                <Container isFluid>
                    <HeaderContent>
                        <Col xs={4} md={2} lg={1}>
                            <LogoSvg width="120px" height="50px" />
                        </Col>
                        <Col md={4} lg={3} xsHidden smHidden>
                            {renderPhone()}
                        </Col>
                        <Col md={4} lg={3} xsHidden smHidden>
                            {renderSearchOptions()}
                        </Col>
                        <Col xs={2} sm={1}>
                            <Dropdown
                                value={currentLocale}
                                activeKey={currentLocale}
                                title={
                                    <Text
                                        fontWeight={TextWeight.BOLD}
                                        fontSize={TextFontSize.S}
                                    >
                                        {currentLocale.toLocaleUpperCase()}
                                    </Text>
                                }
                                trigger={DropdownTrigger.click}
                            >
                                {locales.map((item) => (
                                    <DropdownItem
                                        key={item.code}
                                        eventKey={item.code}
                                        onSelect={() => {
                                            setLocale(item.code);
                                        }}
                                    >
                                        <Text
                                            fontWeight={
                                                currentLocale === item.code
                                                    ? TextWeight.BOLD
                                                    : TextWeight.NORMAL
                                            }
                                            fontSize={TextFontSize.S}
                                        >
                                            {item.language}
                                        </Text>
                                    </DropdownItem>
                                ))}
                            </Dropdown>
                        </Col>
                        <Col xs={2} sm={1}>
                            <HeaderCountryDropdown />
                        </Col>
                        <Col
                            xsHidden
                            smHidden
                            md={4}
                            lg={3}
                            xsOffset={2}
                            smOffset={4}
                            mdOffset={2}
                            lgOffset={4}
                        >
                            {renderB2bLink()}
                        </Col>
                        <Col
                            xsHidden
                            smHidden
                            md={4}
                            lg={4}
                            xsOffset={2}
                            smOffset={4}
                            mdOffset={2}
                            lgOffset={4}
                        >
                            {renderSignBlock()}
                        </Col>
                        <Col mdHidden lgHidden xs={3} xsOffset={1}>
                            <Burger
                                isActive={isBurgerActive}
                                onClick={() =>
                                    setIsBurgerActive(!isBurgerActive)
                                }
                            />
                            {isBurgerActive && (
                                <HeaderBurgerMenu>
                                    <HeaderBurgerMenuGrid>
                                        <HeaderBurgerMenuGridItem>
                                            {renderPhone()}
                                        </HeaderBurgerMenuGridItem>
                                        <HeaderBurgerMenuGridItem>
                                            {renderSearchOptions()}
                                        </HeaderBurgerMenuGridItem>
                                        <HeaderBurgerMenuGridItem>
                                            {renderB2bLink()}
                                        </HeaderBurgerMenuGridItem>
                                        <HeaderBurgerMenuGridItem>
                                            {renderSignBlock()}
                                        </HeaderBurgerMenuGridItem>
                                    </HeaderBurgerMenuGrid>
                                </HeaderBurgerMenu>
                            )}
                        </Col>
                    </HeaderContent>
                </Container>
                <Modal
                    show={isShowSearchOptionsFormModal}
                    onHide={closeSearchOptionsFormModal}
                    width="970px"
                >
                    <HeaderSearchOptionsForm />
                </Modal>
            </HeaderWrapper>
        );
    },
    {
        translations: [APP_CONFIG_KEY],
        dependencies: [
            AppTypes.LocaleStore,
            AppTypes.SettingsStore,
            AppTypes.CountryStore,
        ],
    },
);

Header.displayName = 'Header';
