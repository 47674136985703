import * as React from 'react';
import { CommonProps } from '@serviceplace/core';
import {
    Box,
    Text,
    TextColor,
    SelectTag,
    Input,
    Select,
    DatePicker,
    Button,
    ThemePalette,
} from '@autobooking/components';
import {
    HeaderSearchOptionsFormWrapper,
    HeaderSearchOptionsFormItem,
} from './header-search-options-form.component.styles';

export type HeaderSearchOptionsFormProps = CommonProps;

export const HeaderSearchOptionsForm: React.FC<HeaderSearchOptionsFormProps> = ({
    ...commonProps
}) => {
    return (
        <HeaderSearchOptionsFormWrapper {...commonProps}>
            <HeaderSearchOptionsFormItem>
                <Text fontSize="40px" color={TextColor.Reverse}>
                    Поиск автосервиса
                </Text>
            </HeaderSearchOptionsFormItem>
            <HeaderSearchOptionsFormItem>
                <SelectTag isFullWidth />
            </HeaderSearchOptionsFormItem>
            <HeaderSearchOptionsFormItem>
                <Box display="flex" justifyContent="space-between">
                    <Box width="49%">
                        <Select isFullWidth />
                    </Box>
                    <Box width="49%">
                        <Input />
                    </Box>
                </Box>
            </HeaderSearchOptionsFormItem>
            <HeaderSearchOptionsFormItem>
                <DatePicker
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    format="h:mm aa"
                    placeholder="Время"
                />
            </HeaderSearchOptionsFormItem>
            <HeaderSearchOptionsFormItem>
                <Button size="xl" color={ThemePalette.Red}>
                    Показать станции
                </Button>
            </HeaderSearchOptionsFormItem>
        </HeaderSearchOptionsFormWrapper>
    );
};
