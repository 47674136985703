import { Converter } from '@serviceplace/core';
import { SearchVehicleDto } from '@source/data/dto';
import { SearchVehicleModel } from '@source/data/model';

export type SearchVehicleDtoToModelConverter = Converter<
    SearchVehicleDto,
    SearchVehicleModel
>;

export class SearchVehicleDtoToModelConverterImpl
    extends Converter<SearchVehicleDto, SearchVehicleModel>
    implements SearchVehicleDtoToModelConverter {
    convert(source: SearchVehicleDto): SearchVehicleModel {
        return {
            vehicle: { ...source.vehicle },
            label: source.label,
            value: source.value,
        } as SearchVehicleModel;
    }
}
