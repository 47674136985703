import styled from 'styled-components';

export const PageLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
`;

export const PageLayoutContent = styled.div`
    flex: 1;
`;
