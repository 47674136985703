import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
    Button,
    Text,
    TextColor,
    TextFontSize,
    TextWeight,
    GoogleSvg,
    Input,
    InputType,
    Checkbox,
    CheckboxPalette,
    FacebookSvg,
} from '@autobooking/components';
import {
    AuthFormSignUpTabWrapper,
    AuthFormSignUpTabSvg,
    AuthFormSignUpTabButton,
    AuthFormSignUpTabLabel,
    AuthFormSignUpTabEnterButtons,
    AuthFormSignUpTabForm,
    AuthFormSignUpTabFormItem,
    AuthFormSignUpTabFormButton,
    AuthFormSignUpTabText,
    AuthFormSignUpTabCheckbox,
    AuthFormSignUpTabCheckboxes,
    AuthFormSignUpTabTermsAgree,
    AuthFormSignUpTabTermsAgreeItem,
} from './auth-form-sign-up-tab.component.styles';
import { APP_CONFIG_KEY, AppConfig } from '@source/config/app.config';
import { useYupValidationResolver } from '@source/hooks/yup-validation-resolver.hook';
import { routes } from '@source/routes';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import { AppTypes } from '@source/types';
import { SettingsStore } from '@source/store/settings.store';
import { CountryStore } from '@source/store/country.store';
import { useStateObserver } from '@source/hooks/state-observer.hook';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

enum SignUpForm {
    password = 'password',
    email = 'email',
    terms = 'terms',
    promo = 'promo',
}
export type ModalFormSignUpData = {
    [SignUpForm.password]?: string;
    [SignUpForm.email]?: string;
    [SignUpForm.terms]?: boolean;
    [SignUpForm.promo]?: boolean;
};

export type AuthFormSignUpTabProps = {
    onGoogleAuth?: (accessToken: string) => void;
    onFacebookAuth?: (accessToken: string) => void;
    onSignUp?: (value: ModalFormSignUpData) => void;
};

export const AuthFormSignUpTab: React.FC<AuthFormSignUpTabProps> = withDependencies(
    ({ $injected, onSignUp, onGoogleAuth, onFacebookAuth }) => {
        const { googleId, facebookId } = AppCore.useConfig<AppConfig>(
            APP_CONFIG_KEY,
        );
        const [t] = AppCore.useTranslator(APP_CONFIG_KEY);
        const { currentLocale } = AppCore.useI18n();

        const [settingsStore, countryStore] = $injected.dependencies as [
            SettingsStore,
            CountryStore,
        ];

        const { terms, termsForStation, currentCountry } = useStateObserver(
            () => ({
                terms: settingsStore.settings.terms,
                termsForStation: settingsStore.settings.termsForStation,
                currentCountry: countryStore?.selectedCountry?.countryCode?.toLowerCase(),
            }),
            [],
        );
        const termUrl = routes.relativeLink.build({
            locale: currentLocale,
            country: currentCountry,
            path: `/${terms?.slug}`,
        });
        const termStationUrl = routes.relativeLink.build({
            locale: currentLocale,
            country: currentCountry,
            path: `/${termsForStation?.slug}`,
        });

        const signUpSchema = yup.object().shape({
            [SignUpForm.password]: yup
                .string()
                .label(t('auth: fields: password'))
                .required()
                .min(8)
                .max(30),
            [SignUpForm.email]: yup
                .string()
                .label(t('auth: fields: email'))
                .required()
                .email(), // pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            [SignUpForm.terms]: yup.boolean().oneOf([true]),
            [SignUpForm.promo]: yup.boolean(),
        });

        const singUpResolver = useYupValidationResolver(signUpSchema);

        const defaultValuesForm = {
            [SignUpForm.password]: '',
            [SignUpForm.email]: '',
            [SignUpForm.terms]: false,
            [SignUpForm.promo]: false,
        };

        const {
            handleSubmit: handleSignUp,
            formState,
            watch,
            control: controlSignUp,
        } = useForm({
            resolver: singUpResolver,
            mode: 'all',
            shouldUnregister: false,
            defaultValues: defaultValuesForm,
        });

        const onGoogleAuthSuccess = async (response) => {
            if (response?.accessToken) {
                onGoogleAuth(response.accessToken);
            }
        };
        const onFacebookAuthSuccess = async (response) => {
            if (response?.accessToken) {
                onFacebookAuth(response.accessToken);
            }
        };

        const isTermsAccepted = watch(SignUpForm.terms);

        return (
            <AuthFormSignUpTabWrapper>
                <AuthFormSignUpTabEnterButtons>
                    <AuthFormSignUpTabLabel>
                        <Text fontSize={TextFontSize.S}>
                            {t('auth: sign_up: one_click')}
                        </Text>
                    </AuthFormSignUpTabLabel>
                    <AuthFormSignUpTabButton>
                        <FacebookLogin
                            appId={facebookId}
                            callback={onFacebookAuthSuccess}
                            render={({ onClick }) => (
                                <Button
                                    onClick={onClick}
                                    isOutline
                                    isFullWidth
                                    isDisabled={!isTermsAccepted}
                                >
                                    <AuthFormSignUpTabSvg>
                                        <FacebookSvg size="16px" />
                                    </AuthFormSignUpTabSvg>
                                    <Text
                                        fontWeight={TextWeight.BOLD}
                                        color={TextColor.Link}
                                        fontSize={TextFontSize.M}
                                    >
                                        {t('auth: sign_up: facebook')}
                                    </Text>
                                </Button>
                            )}
                        />
                    </AuthFormSignUpTabButton>
                    <AuthFormSignUpTabButton>
                        <GoogleLogin
                            clientId={googleId}
                            render={({ onClick, disabled }) => (
                                <Button
                                    isOutline
                                    isFullWidth
                                    onClick={onClick}
                                    isDisabled={!isTermsAccepted || disabled}
                                >
                                    <AuthFormSignUpTabSvg>
                                        <GoogleSvg size="16px" />
                                    </AuthFormSignUpTabSvg>
                                    <Text
                                        fontWeight={TextWeight.BOLD}
                                        color={TextColor.Link}
                                        fontSize={TextFontSize.M}
                                    >
                                        {t('auth: sign_up: google')}
                                    </Text>
                                </Button>
                            )}
                            onSuccess={onGoogleAuthSuccess}
                        />
                    </AuthFormSignUpTabButton>
                </AuthFormSignUpTabEnterButtons>
                <AuthFormSignUpTabLabel>
                    <Text fontSize={TextFontSize.S}>
                        {t('auth: or_with_email')}
                    </Text>
                </AuthFormSignUpTabLabel>
                <AuthFormSignUpTabForm onSubmit={handleSignUp(onSignUp)}>
                    <AuthFormSignUpTabFormItem>
                        <Controller
                            control={controlSignUp}
                            name={SignUpForm.email}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <Input
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    name="email"
                                    placeholder={t('auth: fields: email')}
                                    isError={
                                        !!formState.errors[SignUpForm.email]
                                    }
                                    isVerified={
                                        (formState.isSubmitted ||
                                            formState.touchedFields?.[
                                                SignUpForm.email
                                            ]) &&
                                        !formState.errors[SignUpForm.email]
                                    }
                                    errorMessage={
                                        formState.errors[SignUpForm.email]
                                            ?.message
                                    }
                                />
                            )}
                        />
                    </AuthFormSignUpTabFormItem>
                    <AuthFormSignUpTabFormItem>
                        <Controller
                            control={controlSignUp}
                            name={SignUpForm.password}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <Input
                                    placeholder={t('auth: fields: password')}
                                    onChange={onChange}
                                    type={InputType.password}
                                    onBlur={onBlur}
                                    value={value}
                                    name="password"
                                    isError={
                                        !!formState.errors[SignUpForm.password]
                                    }
                                    isVerified={
                                        (formState.isSubmitted ||
                                            formState.touchedFields?.[
                                                SignUpForm.password
                                            ]) &&
                                        !formState.errors[SignUpForm.password]
                                    }
                                    errorMessage={
                                        formState.errors[SignUpForm.password]
                                            ?.message
                                    }
                                />
                            )}
                        />
                    </AuthFormSignUpTabFormItem>
                    <AuthFormSignUpTabCheckboxes>
                        <AuthFormSignUpTabCheckbox>
                            <Controller
                                control={controlSignUp}
                                name={SignUpForm.terms}
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox
                                        isChecked={value}
                                        onChange={onChange}
                                        color={CheckboxPalette.Primary}
                                    >
                                        <AuthFormSignUpTabTermsAgree>
                                            <AuthFormSignUpTabTermsAgreeItem>
                                                <Text>
                                                    {t('auth: terms: agree')}
                                                </Text>
                                            </AuthFormSignUpTabTermsAgreeItem>
                                            <AuthFormSignUpTabTermsAgreeItem>
                                                <a
                                                    href={termUrl}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    <Text
                                                        isLink
                                                        color={TextColor.Link}
                                                    >
                                                        {terms?.title}
                                                    </Text>
                                                </a>
                                            </AuthFormSignUpTabTermsAgreeItem>
                                            <AuthFormSignUpTabTermsAgreeItem>
                                                <Text>
                                                    {t('auth: terms: and')}
                                                </Text>
                                            </AuthFormSignUpTabTermsAgreeItem>
                                            <AuthFormSignUpTabTermsAgreeItem>
                                                <a
                                                    href={termStationUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Text
                                                        isLink
                                                        color={TextColor.Link}
                                                    >
                                                        {termsForStation?.title}
                                                    </Text>
                                                </a>
                                            </AuthFormSignUpTabTermsAgreeItem>
                                            <AuthFormSignUpTabTermsAgreeItem>
                                                <Text>
                                                    {t(
                                                        'auth: terms: autobooking',
                                                    )}
                                                </Text>
                                            </AuthFormSignUpTabTermsAgreeItem>
                                        </AuthFormSignUpTabTermsAgree>
                                    </Checkbox>
                                )}
                            />
                        </AuthFormSignUpTabCheckbox>
                        <AuthFormSignUpTabCheckbox>
                            <Controller
                                control={controlSignUp}
                                name={SignUpForm.promo}
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox
                                        isChecked={value}
                                        onChange={onChange}
                                        color={CheckboxPalette.Primary}
                                    >
                                        <Text>{t('auth: promo_agree')}</Text>
                                    </Checkbox>
                                )}
                            />
                        </AuthFormSignUpTabCheckbox>
                    </AuthFormSignUpTabCheckboxes>
                    <AuthFormSignUpTabFormButton>
                        <Button isFullWidth isDisabled={!formState.isValid}>
                            {t('auth: sign_up: button')}
                        </Button>
                    </AuthFormSignUpTabFormButton>
                </AuthFormSignUpTabForm>
                <AuthFormSignUpTabText>
                    <Text fontSize={TextFontSize.M}>
                        {t('auth: sign_up: desc', {
                            email: t('auth: fields: email'),
                            password: t('auth: fields: password'),
                            sign_up: t('auth: sign_up: button'),
                        })}
                    </Text>
                </AuthFormSignUpTabText>
            </AuthFormSignUpTabWrapper>
        );
    },
    {
        dependencies: [AppTypes.SettingsStore, AppTypes.CountryStore],
    },
);
