import * as React from 'react';

export const useComponentVisible = (initialIsVisible: boolean) => {
    const [isComponentVisible, setIsComponentVisible] = React.useState(
        initialIsVisible,
    );
    const refCurrentComponent = React.useRef(null);

    const handleClickOutside = (event) => {
        if (
            refCurrentComponent.current &&
            !refCurrentComponent.current.contains(event.target)
        ) {
            setIsComponentVisible(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { refCurrentComponent, isComponentVisible, setIsComponentVisible };
};
